export class FloorPlanLocationData {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => FloorPlanLocationData.define(et));
        }
        else if (data != null) {
            return FloorPlanLocationData.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let locationData = new FloorPlanLocationData();
        locationData.entity = data.Entity;
        locationData.entityId = data.EntityId;
        locationData.sourceId = data.SourceId;
        locationData.floorPlanId = data.FloorPlanId;
        locationData.coordinates = data.Coordinates;
        locationData.isDragging = false;
        return locationData;
    }
}
