import { Buffer } from 'buffer';
export async function resizeImage(img, actSize, maxSize, mimeType) {
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    let iw = img.naturalWidth;
    let ih = img.naturalHeight;
    let currentDataURL = '';
    let currentSize = actSize;
    while (currentSize > maxSize) {
        let scale = Math.sqrt(maxSize / currentSize);
        iw = Math.max(1, iw * scale);
        ih = Math.max(1, ih * scale);
        canvas.width = iw;
        canvas.height = ih;
        ctx.clearRect(0, 0, iw, ih);
        ctx.drawImage(img, 0, 0, iw, ih);
        currentDataURL = canvas.toDataURL(mimeType, 1);
        currentSize = await getBase64Size(currentDataURL);
    }
    return currentDataURL;
}
async function getBase64Size(base64) {
    const response = await fetch(base64);
    const blob = await response.blob();
    return blob.size;
}
export function dataUrlToFile(dataUrl, filename, mimeType) {
    const arr = dataUrl.split(',');
    if (arr.length < 2) {
        return undefined;
    }
    const buff = Buffer.from(arr[1], 'base64');
    const name = getNameWithoutExt(filename) + '.' + extractExt(mimeType);
    return new File([buff], name, { type: mimeType });
}
export function arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}
function getNameWithoutExt(filename) {
    return filename.substring(0, filename.lastIndexOf('.')) || filename;
}
function extractExt(mimeType) {
    return mimeType.split('/')[1];
}
