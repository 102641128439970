"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class TooltipBuilder {
    constructor() {
        this._columnWidth = {
            color: "10%",
            name: "60%",
            time: "10%",
            value: "10%",
            weight: "10%"
        };
        this._htmlTooltip = "";
    }
    initTooltip() {
        this._htmlTooltip = "<div>";
        return this;
    }
    endTooltip() {
        this._htmlTooltip += "</div>";
        return this;
    }
    createSectionHead(title) {
        this._htmlTooltip += `<p class='section-head'>${title}</p>`;
        return this;
    }
    createNewTimeSection(time, hasWeight = false) {
        this._htmlTooltip += `<p class='section-time'>${time}</p><table class='section'><col width="${this._columnWidth.color}"><col width="${this._columnWidth.name}"><col width="${this._columnWidth.value}">`;
        if (hasWeight)
            this._htmlTooltip += `<col width="${this._columnWidth.weight}">`;
        return this;
    }
    createPointsSection(hasWeight = false) {
        this._htmlTooltip += `<table class='section'><col width="${this._columnWidth.color}"><col width="${this._columnWidth.name}"><col width="${this._columnWidth.time}"><col width="${this._columnWidth.value}">`;
        if (hasWeight)
            this._htmlTooltip += `<col width="${this._columnWidth.weight}">`;
        return this;
    }
    endSection() {
        this._htmlTooltip += "</table>";
        return this;
    }
    addToSection(point, onlyTimeFormatter, hasNameRemoved = true) {
        this._htmlTooltip += "<tr><td><div class='color-box' style='background-color: " +
            point.color +
            ";'></div></td>";
        if (hasNameRemoved == true)
            this._htmlTooltip += "<td>" + point.name + "</td>";
        this._htmlTooltip += "<td class='time'>" +
            (point.toTime != null ? onlyTimeFormatter(point.time) + " - " + onlyTimeFormatter(point.toTime) : onlyTimeFormatter(point.time))
            + "</td>" + "<td class='value'>" + (point.value ? point.value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + point.serviceSign : "N/A (" + point.serviceSign + ")") + "</td> ";
        if (point.weight != null)
            this._htmlTooltip += `<td class='weight ${point.weight != 0 ? "isAlarm" : "isNormal"}'>Weight:${point.weight} </td>`;
        this._htmlTooltip += "</tr>";
        return this;
    }
    build() {
        return this.endTooltip()._htmlTooltip;
    }
    clear() {
        this._htmlTooltip = "";
        return this;
    }
}
exports.default = TooltipBuilder;
