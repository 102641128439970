"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TooltipPoint = void 0;
class TooltipPoint {
    constructor(id, value, time, toTime, weigth, dataset, serviceSign, yValue) {
        this._id = id;
        this._value = value;
        this._time = time;
        this._toTime = toTime;
        this._weight = weigth;
        this._color = dataset.color;
        this._name = dataset.name;
        this._serviceSign = serviceSign;
        this._yValue = yValue;
    }
    get id() {
        return this._id;
    }
    get value() {
        return this._value;
    }
    get time() {
        return this._time;
    }
    get toTime() {
        return this._toTime;
    }
    get weight() {
        return this._weight;
    }
    get color() {
        return this._color;
    }
    get name() {
        return this._name;
    }
    get serviceSign() {
        return this._serviceSign;
    }
    get yValue() {
        return this._yValue;
    }
    static compareYValues(a, b) {
        if (a.yValue < b.yValue) {
            return -1;
        }
        if (a.yValue > b.yValue) {
            return 1;
        }
        return 0;
    }
    static compareNames(a, b) {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }
}
exports.TooltipPoint = TooltipPoint;
