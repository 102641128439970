import AxiosService from '@/services/api/AxiosService';
import User from '@/entities/User';
import { AdminUser } from '@/entities/models/AdminUser';
import { UserSettings } from '@/entities/UserSettings';
import ApiLinks from '@/entities/ApiLinks';
import Actions from '../permissions/Actions';
import Subjects from '../permissions/Subjects';
import { ApiQueries } from '@/entities/ApiQueries';
import ApiStatus from '@/entities/enums/apiStatuses';
import { vxm } from '@/store/store.vuex';
import { AdminMember } from '@/entities/models/AdminMember';
import ApiResponse from '@/entities/ApiResponse';
import { ReceivedInvitation } from '@/entities/models/ReceivedInvitation';
import LoggedUserManager from '../LoggedUserManager';
var vxDepartmentStore = vxm.departmentStore;
export default class UserRepository {
    constructor(ctx) {
        this.ctx = ctx;
        this.isResellerAdmin = LoggedUserManager.isResellerAdmin(this.ctx);
    }
    async getUsers(departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.LIST, Subjects.USERS)) {
            return [];
        }
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Department.Keys.Users}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return User.fromApi(response.data.data);
    }
    async getCurrentUser() {
        let url = `${ApiLinks.Profile.Me}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new User();
        else
            return User.fromApi(response.data.data);
    }
    async updateCurrentUser(userData) {
        let url = `${ApiLinks.Profile.Me}`;
        let response = await AxiosService.putDataToServer(url, userData, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new User();
        else
            return User.fromApi(response.data.data);
    }
    async deletePhoneNumber() {
        let url = `${ApiLinks.Profile.Phone}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        return response.status == ApiStatus.NO_CONTENT;
    }
    async createPhoneNumber(phoneNumber) {
        let url = `${ApiLinks.Profile.Phone}`;
        let data = new FormData();
        data.set('phoneNumber', phoneNumber);
        let response = await AxiosService.postDataToServer(url, data, this.ctx, false);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return response.data;
    }
    async sendVerificationToCodePhoneNumber(phoneNumber) {
        let url = `${ApiLinks.Profile.SendCode}`;
        let data = new FormData();
        data.set('phoneNumber', phoneNumber);
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return response.data;
    }
    async verifyPhoneNumber(phoneNumber, code) {
        let url = `${ApiLinks.Profile.VerifyNumber}`;
        let data = new FormData();
        data.set('phoneNumber', phoneNumber);
        data.set('verificationCode', code);
        let response = await AxiosService.postDataToServer(url, data, this.ctx, false);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return response.data;
    }
    async enableUser(userId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.ENABLE, Subjects.USERS)) {
            throw 'Forbidden action enableUser';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Department.Keys.Users}/${userId}${ApiLinks.Department.Keys.Enable}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return false;
        else
            return User.fromApi(response.data.data);
    }
    async disableUser(userId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.DISABLE, Subjects.USERS)) {
            throw 'Forbidden action disableUser';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Department.Keys.Users}/${userId}${ApiLinks.Department.Keys.Disable}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status === ApiStatus.NOT_ALLOWED) {
            return response.data.code;
        }
        else if (response.status != ApiStatus.SUCCESS)
            return false;
        else
            return User.fromApi(response.data.data);
    }
    async getUser(userId, departmentId = this.getSelectedDepartmentId(), processError) {
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Users.Keys.Users}/${userId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx, processError);
        if (response.status != ApiStatus.SUCCESS)
            return new User();
        else
            return User.fromApi(response.data.data);
    }
    async removeUserFromDepartment(userId, departmentId = this.getSelectedDepartmentId()) {
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Users.Keys.Users}/${userId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return false;
        else
            return true;
    }
    async changeUserRole(userId, role, departmentId = this.getSelectedDepartmentId()) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Users.Keys.Users}/${userId}${ApiLinks.Users.Keys.Role}`;
        let data = {
            RoleKey: role
        };
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new User();
        else
            return User.fromApi(response.data.data);
    }
    async getUserRoles(departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.MANAGEOWN, Subjects.COMPANY) && !this.isResellerAdmin) {
            throw 'Forbidden action getUserRoles';
        }
        let url = `${ApiLinks.Department.Home}/${departmentId}${ApiLinks.Department.Keys.Roles}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return response.data.data;
    }
    async getUsersAdmin(departmentId = null, pagination = null, sort = null, filter = []) {
        let userAdminResponse = new ApiResponse();
        if (!this.ctx.$ability.can(Actions.LIST, Subjects.ADMIN_USERS)) {
            return userAdminResponse;
        }
        let url = ApiLinks.Users.AdminHome;
        let queries = new ApiQueries();
        if (departmentId) {
            queries.departmentId = departmentId;
        }
        queries.addPagination(pagination);
        if (sort)
            queries.addSorts(sort);
        if (filter.length > 0) {
            queries.addFilters(filter);
        }
        if (queries.hasQueries())
            url += `?${queries.toQueryString()}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return userAdminResponse;
        else {
            userAdminResponse.setPagination(response.data);
            userAdminResponse.setData(AdminUser.fromApi(response.data.data));
            return userAdminResponse;
        }
    }
    async adminEnableUser(userId, data) {
        if (!this.ctx.$ability.can(Actions.ENABLE, Subjects.ADMIN_USERS)) {
            throw 'Forbidden action adminEnableUser';
        }
        let url = `${ApiLinks.Users.AdminHome}/${userId}${ApiLinks.Users.Keys.Enable}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new User();
        else
            return User.fromApi(response.data.data);
    }
    async adminDisableUser(userId, data) {
        if (!this.ctx.$ability.can(Actions.DISABLE, Subjects.ADMIN_USERS)) {
            throw 'Forbidden action adminDisableUser';
        }
        let url = `${ApiLinks.Users.AdminHome}/${userId}${ApiLinks.Users.Keys.Disable}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new User();
        else
            return User.fromApi(response.data.data);
    }
    async adminSaveUserChanges(userId, user) {
        if (!this.ctx.$ability.can(Actions.UPDATE, Subjects.ADMIN_USERS)) {
            throw 'Forbidden action adminDisableUser';
        }
        let url = `${ApiLinks.Users.AdminHome}/${userId}`;
        let response = await AxiosService.putDataToServer(url, user, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new User();
        else
            return User.fromApi(response.data.data);
    }
    async getAdminUser(userId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_USERS)) {
            return new AdminUser();
        }
        let url = `${ApiLinks.Users.AdminHome}/${userId}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new AdminUser();
        else
            return AdminUser.fromApi(response.data.data);
    }
    async adminDeleteUser(userId) {
        let url = `${ApiLinks.Users.AdminHome}/${userId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        return response.status == ApiStatus.NO_CONTENT;
    }
    /**
     * Gets admin user settings and department settings for all departments. User settings are scoped under profile key
     * @param userId
     * @returns admin user settings
     */
    async getAdminUserSettings(userId) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_USERS_SETTINGS)) {
            return new UserSettings();
        }
        let url = `${ApiLinks.Users.AdminHome}/${userId}${ApiLinks.Users.Keys.Settings}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new UserSettings();
        else
            return response.data.data;
    }
    // /**
    //  * Gets admin user settings and department settings for all departments. User settings are scoped under profile key
    //  * @param userId
    //  * @returns admin user settings
    //  */
    // async getDepartmentUserSettings(userId: String, userId: String): Promise<any> {
    //   if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_USERS_SETTINGS)) {
    //     return new UserSettings();
    //   }
    //   let url = `${ApiLinks.Users.AdminHome}/${userId}${ApiLinks.Users.Keys.Settings}`;
    //   let response = await AxiosService.getDataFromServer<AxiosResponse<IResponsePayload<any>>>(url, this.ctx);
    //   if (response.status != ApiStatus.SUCCESS) return new UserSettings();
    //   else return response.data.data;
    // }
    async adminDeletePhoneNumber(userId) {
        if (!this.ctx.$ability.can(Actions.DELETE, Subjects.ADMIN_USERS_PHONE)) {
            throw 'Forbidden action adminDeletePhoneNumber';
        }
        let url = `${ApiLinks.Users.AdminHome}/${userId}${ApiLinks.Users.Keys.PhoneNumber}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        return response.status == ApiStatus.NO_CONTENT;
    }
    async assignAdminUserRole(departmentId, userId, userRole) {
        if (!this.ctx.$ability.can(Actions.ADD, Subjects.ADMIN_DEPARTMENTS_USERS)) {
            throw 'Forbidden action assignAdminUserRole';
        }
        let data = {
            UserId: userId,
            RoleKey: userRole
        };
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Department.Keys.Members}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return response.data;
    }
    async adminCreateUser(userDto) {
        if (!this.ctx.$ability.can(Actions.CREATE, Subjects.ADMIN_USERS)) {
            throw 'Forbidden action adminCreateUser';
        }
        let data = {
            Email: userDto.Email,
            Username: userDto.Username,
            Password: userDto.Password,
            FirstName: userDto.FirstName,
            LastName: userDto.LastName,
            PhoneNumber: userDto.PhoneNumber,
            CustomerData: userDto.CustomerData
        };
        let url = `${ApiLinks.Users.AdminHome}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status === ApiStatus.CREATED)
            return true;
        else
            return false;
    }
    async adminUnassignUser(departmentId, userId) {
        if (!this.ctx.$ability.can(Actions.REMOVE, Subjects.ADMIN_DEPARTMENTS_USERS)) {
            throw 'Forbidden action adminUnassignUser';
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Department.Keys.Members}/${userId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    async getCompanyUsers(departmentId = null) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_DEPARTMENTS_USERS)) {
            return [];
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Department.Keys.Members}`;
        if (departmentId) {
            let queries = new ApiQueries();
            queries.departmentId = departmentId;
            url += `?${queries.toQueryString()}`;
        }
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return AdminMember.fromApi(response.data.data);
    }
    async changeCompanyUserRole(userId, role, departmentId) {
        if (!this.ctx.$ability.can(Actions.CHANGE_ROLE, Subjects.ADMIN_DEPARTMENTS_USERS)) {
            throw 'Forbidden action changeCompanyUserRole';
        }
        let data = {
            RoleKey: role
        };
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Department.Keys.Members}/${userId}${ApiLinks.Users.Keys.Role}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx, false);
        if (response.status === ApiStatus.FORBIDDEN)
            return response.data.code;
        else if (response.status != ApiStatus.SUCCESS)
            return false;
        else
            return User.fromApi(response.data.data);
    }
    async adminDisableDepartmentUser(departmentId, userId) {
        if (!this.ctx.$ability.can(Actions.DISABLE, Subjects.ADMIN_DEPARTMENTS_USERS)) {
            throw 'Forbidden action adminDisableDepartmentUser';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Department.Keys.Members}/${userId}${ApiLinks.Department.Keys.Disable}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx, false);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    async adminEnableDepartmentUser(departmentId, userId) {
        if (!this.ctx.$ability.can(Actions.ENABLE, Subjects.ADMIN_DEPARTMENTS_USERS)) {
            throw 'Forbidden action adminEnableDepartmentUser';
        }
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Department.Keys.Members}/${userId}${ApiLinks.Department.Keys.Enable}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx, false);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    async getInvitationsList() {
        let url = `${ApiLinks.Department.Home}${ApiLinks.Department.Keys.Invitations}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else {
            return ReceivedInvitation.fromApi(response.data.data);
        }
    }
    async acceptInvitation(invitationId) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.Home}${ApiLinks.Department.Keys.Invitations}/${invitationId}${ApiLinks.Department.Keys.Accept}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    async declineInvitation(invitationId) {
        // eslint-disable-next-line vue/max-len
        let url = `${ApiLinks.Department.Home}${ApiLinks.Department.Keys.Invitations}/${invitationId}${ApiLinks.Department.Keys.Decline}`;
        let response = await AxiosService.postDataToServer(url, null, this.ctx);
        if (response.status == ApiStatus.SUCCESS)
            return true;
        else
            return false;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}
