var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("component.acknowledge_modal.title", {
                name: _vm.department.name,
              })
            ) +
            " "
        ),
      ]),
    ]),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _vm.isLoading
          ? _c("b-loading", {
              attrs: {
                "is-full-page": true,
                active: _vm.isLoading,
                "can-cancel": false,
              },
              on: {
                "update:active": function ($event) {
                  _vm.isLoading = $event
                },
              },
            })
          : [
              _c("b-message", { attrs: { type: "is-info", "has-icon": "" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("component.acknowledge_modal.message")) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "has-text-left is-flex subtitle" }, [
                _vm._v(
                  _vm._s(_vm.$t("component.acknowledge_modal.body_title"))
                ),
              ]),
              _vm.alarmIssues
                ? _c(
                    "span",
                    { staticClass: "is-size-5 ml-4 column" },
                    [
                      _c("b-icon", {
                        attrs: {
                          "icon-pack": "fas",
                          icon: "exclamation-circle",
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("component.acknowledge_modal.state.alarm")
                          ) +
                          " "
                      ),
                      _c(
                        "ul",
                        { staticClass: "disc-style ml-6" },
                        _vm._l(_vm.alarmDepartmentIssues, function (issue) {
                          return _c("li", { key: issue.id }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.acknowledge_modal.body_with_value",
                                    {
                                      name: _vm.getLoggerName(
                                        issue.metadata.sourceId
                                      ),
                                      date:
                                        issue.metadata.lastValueTime !== null
                                          ? _vm.dateTimeManager.formatTime(
                                              new Date(
                                                issue.metadata.lastValueTime *
                                                  1000
                                              ),
                                              "",
                                              "DD.MM. HH:mm",
                                              "",
                                              false
                                            )
                                          : "-",
                                      value: parseFloat(
                                        issue.metadata.lastValue
                                      ).toFixed(2),
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ])
                        }),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.warningIssues
                ? _c(
                    "span",
                    { staticClass: "is-size-5 ml-4 column" },
                    [
                      _c("b-icon", {
                        attrs: {
                          "icon-pack": "fas",
                          icon: "exclamation-triangle",
                        },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("component.acknowledge_modal.state.alarm")
                          ) +
                          " "
                      ),
                      _c(
                        "ul",
                        { staticClass: "disc-style ml-6" },
                        _vm._l(_vm.warningDepartmentIssues, function (issue) {
                          return _c("li", { key: issue.id }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "component.acknowledge_modal.body_with_value",
                                    {
                                      name: _vm.getLoggerName(
                                        issue.metadata.sourceId
                                      ),
                                      date:
                                        issue.metadata.lastValueTime !== null
                                          ? _vm.dateTimeManager.formatTime(
                                              new Date(
                                                issue.metadata.lastValueTime *
                                                  1000
                                              ),
                                              "",
                                              "DD.MM. HH:mm",
                                              "",
                                              false
                                            )
                                          : "-",
                                      value: parseFloat(
                                        issue.metadata.lastValue
                                      ).toFixed(2),
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ])
                        }),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.issueCountByType(_vm.IssueType.LOW_BATTERY)
                ? _c(
                    "span",
                    { staticClass: "is-size-5 ml-4 column" },
                    [
                      _c("b-icon", {
                        attrs: { "icon-pack": "fas", icon: "battery-quarter" },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("component.acknowledge_modal.state.battery")
                          ) +
                          " "
                      ),
                      _c(
                        "ul",
                        { staticClass: "disc-style ml-6" },
                        _vm._l(
                          _vm.departmentIssueByType(_vm.IssueType.LOW_BATTERY),
                          function (issue) {
                            return _c("li", { key: issue.id }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getCompanyLoggerName(
                                      issue.metadata.companyLoggerId
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.issueCountByType(_vm.IssueType.SOURCE_OFFLINE)
                ? _c(
                    "span",
                    { staticClass: "is-size-5 ml-4 column" },
                    [
                      _c("b-icon", {
                        attrs: { "icon-pack": "fas", icon: "wifi" },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "component.acknowledge_modal.state.source_offline"
                            )
                          ) +
                          " "
                      ),
                      _c(
                        "ul",
                        { staticClass: "disc-style ml-6" },
                        _vm._l(
                          _vm.departmentIssueByType(
                            _vm.IssueType.SOURCE_OFFLINE
                          ),
                          function (issue) {
                            return _c("li", { key: issue.id }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.acknowledge_modal.body", {
                                      name: _vm.getLoggerName(
                                        issue.metadata.sourceId
                                      ),
                                      date:
                                        issue.metadata.lastSeen !== null
                                          ? _vm.dateTimeManager.formatTime(
                                              new Date(
                                                issue.metadata.lastSeen * 1000
                                              ),
                                              "",
                                              "DD.MM. HH:mm",
                                              "",
                                              false
                                            )
                                          : "-",
                                    })
                                  ) +
                                  " "
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.issueCountByType(_vm.IssueType.DEVICE_OFFLINE)
                ? _c(
                    "span",
                    { staticClass: "is-size-5 ml-4 column" },
                    [
                      _c("b-icon", {
                        attrs: { "icon-pack": "fas", icon: "wifi" },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "component.acknowledge_modal.state.device_offline"
                            )
                          ) +
                          " "
                      ),
                      _c(
                        "ul",
                        { staticClass: "disc-style ml-6" },
                        _vm._l(
                          _vm.departmentIssueByType(
                            _vm.IssueType.DEVICE_OFFLINE
                          ),
                          function (issue) {
                            return _c("li", { key: issue.id }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("component.acknowledge_modal.body", {
                                      name: _vm.getDeviceName(
                                        issue.metadata.deviceInstanceId
                                      ),
                                      date:
                                        issue.metadata.lastSeen !== null
                                          ? _vm.dateTimeManager.formatTime(
                                              new Date(
                                                issue.metadata.lastSeen * 1000
                                              ),
                                              "",
                                              "DD.MM. HH:mm",
                                              "",
                                              false
                                            )
                                          : "-",
                                    })
                                  ) +
                                  " "
                              ),
                            ])
                          }
                        ),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.issueCountByType(_vm.IssueType.MAINTENANCE)
                ? _c(
                    "span",
                    { staticClass: "is-size-5 ml-4 column" },
                    [
                      _c("b-icon", {
                        attrs: { "icon-pack": "fas", icon: "wrench" },
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "component.acknowledge_modal.state.maintenance"
                            )
                          ) +
                          " "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
      ],
      2
    ),
    _c(
      "footer",
      {
        staticClass: "modal-card-foot",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _c(
          "b-button",
          {
            on: {
              click: function ($event) {
                return _vm.closeModal()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("generals.close")))]
        ),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: {
              click: function ($event) {
                return _vm.acknowledge()
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("component.acknowledge_modal.acknowledge")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }