export var DepartmentSettingsKeys;
(function (DepartmentSettingsKeys) {
    DepartmentSettingsKeys["SOURCE"] = "Source";
    DepartmentSettingsKeys["DEVICES"] = "Devices";
    DepartmentSettingsKeys["SETUP_REQUIRED"] = "setupRequired";
    DepartmentSettingsKeys["OFFLINE_THRESHOLD"] = "OfflineThreshold";
    DepartmentSettingsKeys["REPEATED_THRESHOLD"] = "RepeatedThreshold";
    DepartmentSettingsKeys["OFFLINE_CHECK"] = "OfflineCheck";
    DepartmentSettingsKeys["DO_NOT_NOTIFY"] = "DoNotNotifyWhenDeviceOffline";
    DepartmentSettingsKeys["REPORTS"] = "Reports";
    DepartmentSettingsKeys["PDF"] = "Pdf";
    DepartmentSettingsKeys["CONFIGURATION"] = "Configuration";
    DepartmentSettingsKeys["THERMO_MAP"] = "ThermoMap";
    DepartmentSettingsKeys["NOTIFICATIONS"] = "Notifications";
    DepartmentSettingsKeys["BUZZER"] = "Buzzer";
    DepartmentSettingsKeys["DEVICE_ID"] = "DeviceId";
})(DepartmentSettingsKeys || (DepartmentSettingsKeys = {}));
export class DepartmentSettings {
    constructor() {
        this.setupRequired = true;
        /*TODO: Tieto default hodnoty nie su staticke a daju sa zmenit v system settingoch.
        V pripade ze si user nenastavi vlastne hodnoty sa vsak neposielaju a tym padom FE zobrazuje nespravne hodnoty.*/
        this.Source = {
            OfflineThreshold: 900,
            DoNotNotifyWhenDeviceOffline: 'false'
        };
        this.Devices = {
            OfflineCheck: { RepeatedThreshold: 300 }
        };
        this.Reports = {
            Pdf: {
                Configuration: null
            }
        };
        this.Notifications = { Buzzer: { DeviceId: null } };
        this.ThermoMap = { Configuration: null };
    }
    static fromApi(data) {
        return DepartmentSettings.define(data);
    }
    static define(data) {
        let companySettings = new DepartmentSettings();
        if (typeof data.setupRequired == 'string')
            companySettings.setupRequired = toBoolean(data.setupRequired);
        else
            companySettings.setupRequired = data.setupRequired || companySettings.setupRequired;
        companySettings.Source.OfflineThreshold = data.Source?.OfflineThreshold || companySettings.Source?.OfflineThreshold;
        companySettings.Source.DoNotNotifyWhenDeviceOffline =
            data.Source?.DoNotNotifyWhenDeviceOffline || companySettings.Source?.DoNotNotifyWhenDeviceOffline;
        companySettings.Devices.OfflineCheck.RepeatedThreshold =
            data.Devices?.OfflineCheck?.RepeatedThreshold || companySettings.Devices?.OfflineCheck?.RepeatedThreshold;
        companySettings.Notifications.Buzzer.DeviceId =
            data.Notifications?.Buzzer?.DeviceId || companySettings.Notifications?.Buzzer?.DeviceId;
        if (typeof data.Reports?.Pdf?.Configuration == 'string')
            companySettings.Reports.Pdf.Configuration = toObject(data.Reports?.Pdf?.Configuration);
        else
            companySettings.Reports.Pdf.Configuration =
                data.Reports?.Pdf?.Configuration || companySettings.Reports?.Pdf?.Configuration;
        if (typeof data.ThermoMap?.Configuration == 'string')
            companySettings.ThermoMap.Configuration = toObject(data.ThermoMap?.Configuration);
        else
            companySettings.ThermoMap.Configuration =
                data.ThermoMap?.Configuration || companySettings.ThermoMap?.Configuration;
        return companySettings;
    }
}
function toBoolean(stringValue) {
    if (stringValue.toLowerCase() === 'true') {
        return true;
    }
    else if (stringValue.toLowerCase() === 'false')
        return false;
}
function toObject(stringValue) {
    let configuration = JSON.parse(stringValue);
    return configuration;
}
