import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import CompanyAdministration from '@/views/components/administration/CompanyAdministration.vue';
import TierDetail from '@/views/components/tier/TierDetail.vue';
import UserAdministration from '@/views/components/administration/UserAdministration.vue';
import UserRepository from '@/services/repository/UserRepository';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import TiersRepository from '@/services/repository/TiersRepository';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import DateTimeManager from '@/services/DateTimeManager';
import VueUtilities from '@/services/VueUtilities';
import AppConfig from '@/configLoader';
import { Department } from '@/entities/models/Department';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import SentInvitationsManagement from '@/views/components/invitation/SentInvitationsManagement.vue';
import { vxm } from '@/store/store.vuex';
import InviteUserModal from '../components/administration/InviteUserModal.vue';
import ResendInvitationModal from '../components/invitation/ResendInvitationModal.vue';
import FloorPlanUpload from '@/views/components/administration/FloorPlanUpload.vue';
import FloorPlanOverview from '@/views/components/administration/FloorPlanOverview.vue';
import SourceRepository from '@/services/repository/SourceRepository';
import { FloorPlan } from '@/entities/models/FloorPlan';
import ManageUserPresets from '@/views/components/notification/ManageUserPresets.vue';
import { NotificationPresetWithSubscriptions } from '@/entities/notifications/NotificationPreset';
import NotificationSubscriptionRepository from '@/services/repository/NotificationSubscriptionRepository';
import NotificationPresetType from '@/entities/enums/NotificationPresetType';
import LoggedUserManager from '@/services/LoggedUserManager';
import DeviceRepository from '@/services/repository/DeviceRepository';
import { arrayBufferToBase64 } from '@/services/FileManager';
var notificationSubscriptionsRepository;
var userRepository;
var userSettingsRepository;
var tiersRepository;
var departmentRepository;
var sourceRepository;
var vxDepartmentStore = vxm.departmentStore;
var deviceRepository;
let CompanyManagement = class CompanyManagement extends Vue {
    constructor() {
        super(...arguments);
        this.currentDepartment = new Department();
        this.userList = [];
        this.sentInvitations = [];
        this.dateTimeManager = null;
        this.isLoading = true;
        this.tier = null;
        this.isFavorite = false;
        this.currentConfig = AppConfig.getConfig();
        this.isInviteModalActive = false;
        this.isResendInvitationModalActive = false;
        this.resendInvitation = null;
        this.sources = [];
        this.devices = [];
        this.floorPlans = [];
        this.floorPlanThumbnails = [];
        this.selectedFloorPlanImage = null;
        this.selectedFloorPlanLocationData = null;
        this.selectedFloorPlan = null;
        this.presets = [];
        this.SELECTED_DEPARTMENT_ID = null;
        this.currentUser = null;
        this.types = [];
        this.isUploadingFloorPlan = false;
        this.isUpdatingFloorPlan = false;
    }
    get floorplanEnabled() {
        return AppConfig.getConfig().features.floor_plan;
    }
    get departmentPresets() {
        let presets = this.presets.filter((x) => x.type == NotificationPresetType.DEPARTMENT);
        if (!presets.find((x) => x.id == null)) {
            presets.push(NotificationPresetWithSubscriptions.createDefaultPreset(this.types));
        }
        return presets;
    }
    get isAdmin() {
        return LoggedUserManager.isAdmin(this);
    }
    async created() {
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        userRepository = new UserRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        tiersRepository = new TiersRepository(this);
        departmentRepository = new DepartmentRepository(this);
        sourceRepository = new SourceRepository(this);
        deviceRepository = new DeviceRepository(this);
        notificationSubscriptionsRepository = new NotificationSubscriptionRepository(this);
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        this.SELECTED_DEPARTMENT_ID = this.getSelectedDepartmentId();
        this.currentUser = await userRepository.getCurrentUser();
        let allPromises = [];
        allPromises.push(departmentRepository.getDepartment());
        allPromises.push(userRepository.getUsers());
        allPromises.push(tiersRepository.getActiveTier());
        allPromises.push(userSettingsRepository.loadUserSettings());
        allPromises.push(departmentRepository.getSentInvitations());
        allPromises.push(sourceRepository.getVisibleSources());
        allPromises.push(notificationSubscriptionsRepository.getNotificationPresets(this.currentUser.apiUserId));
        allPromises.push(notificationSubscriptionsRepository.getNotificationTypes());
        allPromises.push(deviceRepository.getDevices());
        if (this.floorplanEnabled) {
            allPromises.push(departmentRepository.getFloorPlans());
        }
        Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1], response[2], response[3], response[4], response[5], response[6], response[7], response[8], response[9]);
        });
    }
    async processLoadedDataForPage(department, companyUsers, tier, settings, sentInvitations, sources, presets, types, devices, floorPlans) {
        this.currentDepartment = department;
        this.userList = companyUsers;
        this.tier = tier;
        this.sentInvitations = sentInvitations;
        this.isFavorite = settings.preferences.favoriteDepartmentId ? true : false;
        this.sources = sources.getData();
        //Add default preset (used when creating new)
        this.presets = presets;
        this.types = types;
        this.devices = devices.getData();
        if (this.floorplanEnabled) {
            this.floorPlans = floorPlans;
            if (this.floorPlans?.length > 0) {
                this.loadThumbnails();
                // load first floorplan
                this.selectedFloorPlan = floorPlans[0];
                await this.loadDataForFloorplan(floorPlans[0].id);
            }
        }
        this.isLoading = false;
    }
    async loadDataForFloorplan(id) {
        let allPromises = [];
        allPromises.push(departmentRepository.getFloorPlanImage(id));
        allPromises.push(departmentRepository.getFloorPlanData(id));
        await Promise.all(allPromises).then((response) => {
            if (response.includes(null)) {
                VueUtilities.openErrorToast(this, this.$t('component.floorPlan.loadingError'));
            }
            this.selectedFloorPlanImage = response[0]?.data || null;
            this.selectedFloorPlanLocationData = response[1];
        });
    }
    loadThumbnails() {
        let allPromises = [];
        this.floorPlanThumbnails = [];
        this.floorPlans.forEach((floorPlan) => allPromises.push(departmentRepository.getFloorPlanThumbnail(floorPlan.id)));
        Promise.all(allPromises).then((response) => {
            if (response.includes(null)) {
                VueUtilities.openErrorToast(this, this.$t('component.floorPlan.loadingError'));
            }
            response.forEach((res, i) => {
                if (res) {
                    let thumbnail = '';
                    thumbnail = 'data:' + this.floorPlans[i].fileDetails.mimeType + ';base64, ' + arrayBufferToBase64(res.data);
                    this.floorPlanThumbnails.push(thumbnail);
                }
                else {
                    this.floorPlanThumbnails.push('');
                }
            });
        });
    }
    closeInviteUserModal(data) {
        this.isInviteModalActive = false;
        this.loadData();
    }
    closeResendInviteModal() {
        this.isResendInvitationModalActive = false;
        this.loadData();
    }
    openResendInviteModal(invitation) {
        this.resendInvitation = invitation;
        this.isResendInvitationModalActive = true;
    }
    openInviteModal() {
        this.isInviteModalActive = true;
    }
    async saveName(name) {
        this.isLoading = true;
        let data = {
            Name: name
        };
        let response = await departmentRepository.updateDepartment(data);
        if (this.currentDepartment == null) {
            VueUtilities.openErrorToast(this, this.$t('component.settings.administration.update_failure'));
        }
        else {
            VueUtilities.openSuccessToast(this, this.$t('component.settings.administration.update_success'));
            this.currentDepartment = response;
        }
        this.isLoading = false;
    }
    async saveFavorite(id) {
        await userSettingsRepository.saveUserSetting([UserSettingsKeys.PREFERENCES, UserSettingsKeys.FAVORITE_DEPARTMENT_ID], id);
        VueUtilities.openSuccessToast(this, this.$t('component.settings.administration.update_success'));
        this.isFavorite = id ? true : false;
    }
    async revokeInvitation(id) {
        await departmentRepository.cancelSentInvitation(id);
        VueUtilities.openSuccessToast(this, this.$t('component.invitation.cancel_successful'));
        this.loadData();
    }
    async uploadFloorPlan(value, name, image, thumbnail, positions) {
        if (!this.isUploadingFloorPlan) {
            this.selectedFloorPlanImage = null;
            this.selectedFloorPlan = new FloorPlan();
            this.selectedFloorPlanLocationData = new Array();
        }
        else {
            this.isLoading = true;
            let res = await departmentRepository.uploadFloorPlan(name, image, thumbnail, positions);
            if (res)
                VueUtilities.openSuccessToast(this, this.$t('component.floorPlan.success'));
            else
                VueUtilities.openErrorToast(this, this.$t('component.floorPlan.failure'));
            this.loadData();
        }
        this.isUploadingFloorPlan = value;
    }
    async updateFloorPlan(value, id, name, positions) {
        if (!this.isUpdatingFloorPlan) {
            this.selectedFloorPlan = this.floorPlans.find((x) => x.id === id);
        }
        else {
            this.isLoading = true;
            let allPromises = [];
            allPromises.push(departmentRepository.updateFloorPlan(id, name));
            allPromises.push(departmentRepository.updateFloorPlanData(id, positions));
            await Promise.all(allPromises)
                .then((response) => {
                VueUtilities.openSuccessToast(this, this.$t('component.floorPlan.update_success'));
                this.loadData();
            })
                .catch((err) => {
                VueUtilities.openErrorToast(this, this.$t('component.floorPlan.update_failure'));
            });
        }
        this.isUpdatingFloorPlan = value;
    }
    async cancelEditingFloorplan() {
        this.isUpdatingFloorPlan = false;
        this.isUploadingFloorPlan = false;
        if (this.floorPlans.length > 0 && !this.floorPlans.find((x) => x.id === this.selectedFloorPlan.id)) {
            this.isLoading = true;
            this.selectedFloorPlan = this.floorPlans[0];
            await this.loadDataForFloorplan(this.floorPlans[0].id);
            this.isLoading = false;
        }
    }
    async setSelectedFloorplan(id) {
        this.selectedFloorPlan = this.floorPlans.find((x) => x.id === id);
        await this.loadDataForFloorplan(this.selectedFloorPlan.id);
        this.$refs.floorplanOverviewComp.loadData();
    }
    async deleteFloorPlan(id) {
        this.isLoading = true;
        this.$buefy.dialog.confirm({
            title: `${this.$t('component.floorPlan.deletePlan')}`,
            message: `${this.$t('component.floorPlan.deleteFloorPlanDialog.message')}`,
            confirmText: `${this.$t('component.floorPlan.deleteFloorPlanDialog.confirm')}`,
            cancelText: `${this.$t('component.floorPlan.deleteFloorPlanDialog.cancel')}`,
            type: 'is-danger',
            hasIcon: true,
            onConfirm: async () => {
                this.isLoading = true;
                let res = await departmentRepository.deleteFloorPlan(id);
                if (res) {
                    VueUtilities.openSuccessToast(this, this.$t('component.floorPlan.deleteFloorPlanDialog.success'));
                    this.loadData();
                }
                else
                    VueUtilities.openErrorToast(this, this.$t('component.floorPlan.deleteFloorPlanDialog.failure'));
            }
        });
        this.isLoading = false;
    }
    async createPreset(dto) {
        this.isLoading = true;
        let result = null;
        result = await notificationSubscriptionsRepository.createNotificationPreset(dto);
        if (result === true) {
            this.presets = await notificationSubscriptionsRepository.getNotificationPresets(this.currentUser.apiUserId);
            VueUtilities.openSuccessToast(this, this.$t('component.notification.presets.create_success'));
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.notification.presets.create_failure'));
        }
        this.isLoading = false;
    }
    async updatePreset(presetId, dto) {
        this.isLoading = true;
        let result = await notificationSubscriptionsRepository.updateNotificationPreset(presetId, dto);
        if (result === true) {
            this.presets = await notificationSubscriptionsRepository.getNotificationPresets(this.currentUser.apiUserId);
            VueUtilities.openSuccessToast(this, this.$t('component.notification.presets.edit_success'));
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.notification.presets.edit_failure'));
        }
        this.isLoading = false;
    }
    async deletePreset(presetId) {
        this.isLoading = true;
        let result = await notificationSubscriptionsRepository.deleteNotificationPreset(presetId);
        if (result === true) {
            this.presets = await notificationSubscriptionsRepository.getNotificationPresets(this.currentUser.apiUserId);
            VueUtilities.openSuccessToast(this, this.$t('component.notification.presets.delete_success'));
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.notification.presets.delete_failure'));
        }
        this.isLoading = false;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
};
CompanyManagement = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.LIST, Subjects.USERS) && vm.$ability.can(Actions.MANAGE, Subjects.FEATURES)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        beforeRouteUpdate(to, from, next) {
            if (this.$ability.can(Actions.LIST, Subjects.USERS) &&
                this.$ability.can(Actions.MANAGE, Subjects.FEATURES)) {
                if (this.SELECTED_DEPARTMENT_ID != this.getSelectedDepartmentId()) {
                    this.loadData();
                }
                next();
            }
            else {
                this.$route.params.departmentId = vxm.departmentStore.selectedDepartment.id;
                next({
                    name: 'settings',
                    params: this.$route.params
                });
                VueUtilities.openErrorToast(this, this.$t('missing_permissions.title'));
            }
        },
        beforeRouteLeave(to, from, next) {
            if (this.$refs.floorPlanUploadComp &&
                this.$refs.floorPlanUploadComp?.hasChanges &&
                to.query.confirmed != 'true') {
                this.$refs.floorPlanUploadComp.routeToNavigate = to;
                this.$refs.floorPlanUploadComp.isUnsavedChangesModalActive = true;
            }
            else {
                next();
            }
        },
        components: {
            CompanyAdministration,
            UserAdministration,
            TierDetail,
            SentInvitationsManagement,
            InviteUserModal,
            ResendInvitationModal,
            FloorPlanUpload,
            ManageUserPresets,
            FloorPlanOverview
        }
    })
], CompanyManagement);
export default CompanyManagement;
