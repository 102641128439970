"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegendOrientation = void 0;
var LegendOrientation;
(function (LegendOrientation) {
    LegendOrientation[LegendOrientation["ROW"] = 0] = "ROW";
    LegendOrientation[LegendOrientation["COLUMN"] = 1] = "COLUMN";
    LegendOrientation[LegendOrientation["COLUMN_HALF"] = 2] = "COLUMN_HALF";
    LegendOrientation[LegendOrientation["COLUMN_ONE_THIRD"] = 3] = "COLUMN_ONE_THIRD";
    LegendOrientation[LegendOrientation["COLUMN_ONE_QUARTER"] = 4] = "COLUMN_ONE_QUARTER";
})(LegendOrientation = exports.LegendOrientation || (exports.LegendOrientation = {}));
