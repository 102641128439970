export class Point {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}
export class Room {
    constructor() {
        this.corners = [];
        this.sensors = [];
        this.doors = [];
    }
    isInsideRoom(point, absolute = false) {
        let x = point.x, y = point.y;
        let inside = false;
        for (let i = 0, j = this.corners.length - 1; i < this.corners.length; j = i++) {
            let xi = this.corners[i].x, yi = this.corners[i].y;
            let xj = this.corners[j].x, yj = this.corners[j].y;
            if (absolute) {
                (xi = this.corners[i].absX), (yi = this.corners[i].absY);
                (xj = this.corners[j].absX), (yj = this.corners[j].absY);
            }
            let intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect)
                inside = !inside;
        }
        return inside;
    }
    getConnectedRooms() {
        var visited = [this];
        var doorsToVisit = [...this.doors];
        while (doorsToVisit.length > 0) {
            let d = doorsToVisit.pop();
            if (!visited.some((v) => v.id == d.room1.id)) {
                visited.push(d.room1);
                doorsToVisit.push(...d.room1.doors);
            }
            if (!visited.some((v) => v.id == d.room2.id)) {
                visited.push(d.room2);
                doorsToVisit.push(...d.room2.doors);
            }
        }
        return visited;
    }
    identifyConvexCorners() {
        const eps = 1 / 1000;
        for (let i = 0; i < this.corners.length - 1; i++) {
            let il = i - 1;
            if (il < 0)
                il = this.corners.length - 1;
            let ir = i + 1;
            if (ir > this.corners.length - 1)
                ir = 0;
            let pl = this.corners[il];
            let pc = this.corners[i];
            let pr = this.corners[ir];
            let mx = Math.sign((pc.absX - pl.absX + (pc.absX - pr.absX)) / 2);
            let my = Math.sign((pc.absY - pl.absY + (pc.absY - pr.absY)) / 2);
            let x_offset = mx * eps;
            let y_offset = my * eps;
            pc.offsetX = x_offset;
            pc.offsetY = y_offset;
            pc.convex = this.isInsideRoom(new Point(pc.absX + pc.offsetX, pc.absY + pc.offsetY), true);
        }
    }
}
export class LayoutData {
    constructor() {
        this.sensors = [];
        this.rooms = [];
        this.doors = [];
    }
}
export class BoundingBox {
    constructor() {
        this.minX = Infinity;
        this.maxX = 0;
        this.minY = Infinity;
        this.maxY = 0;
        this.absMinX = Infinity;
        this.absMaxX = 0;
        this.absMinY = Infinity;
        this.absMaxY = 0;
    }
}
export class Corner {
    constructor(x, y, ppm) {
        this.x = Math.round(x / ppm);
        this.y = Math.round(y / ppm);
        this.absX = x;
        this.absY = y;
    }
}
export class Sensor {
    constructor(id, x, y, t, ppm, index) {
        this.id = id;
        this.x = Math.round(x / ppm);
        this.y = Math.round(y / ppm);
        this.absX = x;
        this.absY = y;
        this.t = t;
        this.index = index;
    }
    assignSource(sourceId) {
        this.assignedSourceId = sourceId;
    }
}
export class Door {
    constructor() {
        this.rawCorners = [];
        this.corners = [];
    }
}
export class MapValue {
    constructor(v, type) {
        this.v = null;
        this.type = '';
        this.v = v;
        this.type = type;
    }
}
export class ThermoMapConfig {
}
