import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import CompanyLoggerRepository from '@/services/repository/CompanyLoggerRepository';
import CalibrationStatus from '@/entities/enums/CalibrationStatus';
import CreateCalibrationModal from '@/views/components/admin/loggers/CreateCalibrationModal.vue';
import DecimalConversion from '@/services/DecimalConversion';
import VueUtilities from '@/services/VueUtilities';
var companyLoggerRepository;
let CalibrationInfoComponent = class CalibrationInfoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.DecimalConversion = DecimalConversion;
        this.CalibrationStatus = CalibrationStatus;
        this.isModalActive = false;
        this.isLoading = false;
        this.isEditing = false;
        this.expireAt = new Date();
    }
    created() {
        companyLoggerRepository = new CompanyLoggerRepository(this);
        this.expireDate();
    }
    async deleteCalibration() {
        let deleteResult = await companyLoggerRepository.adminDeleteCalibration(this.companyLogger.companyLoggerId);
        if (deleteResult == true)
            this.reloadLogger();
    }
    openCalibrationModal() {
        this.isModalActive = true;
    }
    expireDate() {
        if (this.companyLogger.calibration) {
            let date = new Date(this.dateTimeManager.formatTime(new Date(this.companyLogger.calibration.expiresAt * 1000), 'UTC', 'YYYY-MM-DD', ''));
            this.expireAt = date;
        }
    }
    save() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                if (this.companyLogger.calibration.calibratedAt * 1000 > this.expireAt.getTime()) {
                    VueUtilities.openErrorToast(this, this.$t('admin.component.loggers.calibration.create_calibration.msg.date_compare_calibration').toString());
                    return;
                }
                else {
                    this.isLoading = true;
                    let expireDateUTC = Date.UTC(this.expireAt.getFullYear(), this.expireAt.getMonth(), this.expireAt.getDate(), 23, 59, 59);
                    let calibrationUpdateDTO = {
                        ExpiresAt: new Date(expireDateUTC).toISOString()
                    };
                    let createResult = await companyLoggerRepository.adminUpdateCalibration(this.companyLogger.companyLoggerId, calibrationUpdateDTO);
                    if (createResult == true)
                        this.reloadLogger();
                }
            }
        });
    }
    toggleEdit() {
        this.isEditing = !this.isEditing;
        if (this.isEditing) {
            this.expireDate();
        }
    }
    closeModal() {
        this.isModalActive = false;
    }
    async saveCalibration(calibration) {
        this.closeModal();
        let createResult = await companyLoggerRepository.adminCreateCalibration(this.companyLogger.companyLoggerId, calibration);
        if (createResult == true)
            this.reloadLogger();
    }
    reloadLogger() {
        this.$emit('reloadLogger');
    }
    openConfirmDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('admin.component.loggers.calibration.delete.title'),
            message: this.$t('admin.component.loggers.calibration.delete.message', {
                loggerId: this.companyLogger.serialNumber,
                mac: this.companyLogger.macAddress
            }),
            confirmText: this.$t('admin.component.loggers.calibration.delete.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.deleteCalibration()
        });
    }
    async downloadCalibration(id) {
        this.isLoading = true;
        await companyLoggerRepository.adminDownloadCalibrationProtocol(id);
        this.isLoading = false;
    }
    hasCalibrationProtocolToDownload(logger) {
        if (logger == null || logger.calibration == null || logger.calibration.calibratedAt <= 0)
            return false;
        else
            return true;
    }
};
__decorate([
    Prop({ type: Object })
], CalibrationInfoComponent.prototype, "companyLogger", void 0);
__decorate([
    Prop({ type: Object })
], CalibrationInfoComponent.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], CalibrationInfoComponent.prototype, "isLayoutList", void 0);
CalibrationInfoComponent = __decorate([
    Component({ components: { CreateCalibrationModal } })
], CalibrationInfoComponent);
export default CalibrationInfoComponent;
