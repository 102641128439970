import { ReportRecord } from '@/entities/models/ReportRecord';
import i18n from '@/lang/lang';
import EventType from '@/entities/EventType';
import GeneralChartDataConverter from './GeneralChartDataConverter';
import { getStatisticsTypeValues } from '@/entities/enums/statisticsType';
import UnitNames from '@/entities/enums/UnitNames';
export default class ReportChartDataConverter extends GeneralChartDataConverter {
    constructor(reportRecords, eventTypes, sourceGroupSources, annotationsForSources = []) {
        super();
        let measuredEventTypes = EventType.getMeasuredEventTypes(eventTypes);
        this._services = this.createServices(reportRecords, eventTypes, annotationsForSources);
        this._data = this.convertData(reportRecords, measuredEventTypes, sourceGroupSources, annotationsForSources);
        this._boundaries = this.convertBoundaries(sourceGroupSources, eventTypes);
    }
    get services() {
        return this._services;
    }
    get data() {
        return this._data;
    }
    get boundaries() {
        return this._boundaries;
    }
    get hasData() {
        let hasData = false;
        this._data.forEach((dataset) => {
            if (dataset.data.length > 0)
                hasData = true;
        });
        return hasData;
    }
    convertBoundaries(sourceGroupSources, eventTypes) {
        let convertedBoundaries = new Array();
        let hasMoreBoundariesForOneService = false;
        sourceGroupSources.forEach((source) => {
            source.boundaries.forEach((boundary) => {
                let boundaryEventType = eventTypes.find((x) => x.slug == boundary.eventTypeSlug);
                if (!boundaryEventType)
                    return;
                let newBoundary = {
                    serviceId: boundaryEventType.type,
                    values: [boundary.lowerAlarmBoundary.Value, boundary.upperAlarmBoundary.Value]
                };
                let alreadyExists = convertedBoundaries.filter((x) => x.serviceId == newBoundary.serviceId &&
                    x.values[0] == newBoundary.values[0] &&
                    x.values[1] == newBoundary.values[1]).length >= 1;
                if (!alreadyExists)
                    convertedBoundaries.push(newBoundary);
                hasMoreBoundariesForOneService =
                    convertedBoundaries.filter((x) => x.serviceId == newBoundary.serviceId).length > 1;
            });
        });
        if (hasMoreBoundariesForOneService)
            return [];
        else
            return convertedBoundaries;
    }
    convertData(reportRecords, eventTypes, sourceGroupSources, annotationsForSources) {
        let convertedData = new Array();
        let timeMin = 0;
        let timeMax = 0;
        Object.keys(reportRecords).forEach((sourceId) => {
            let recordValues = [];
            let typesForSource = [...new Set(reportRecords[sourceId].flatMap((x) => x.values).map((x) => x.eventType))];
            let statisticTypesForSource = [...new Set(reportRecords[sourceId].flatMap((x) => x.values).map((x) => x.key))];
            reportRecords[sourceId].forEach((record) => {
                if (record.values.length > 0)
                    recordValues = recordValues.concat(record.values);
                else {
                    typesForSource.forEach((type) => {
                        statisticTypesForSource.forEach((statisticType) => {
                            let nullValue = {
                                datepoint: record.datepoint,
                                time: record.datepoint,
                                value: { Value: null, Unit: '' },
                                eventType: type,
                                key: statisticType
                            };
                            recordValues.push(nullValue);
                        });
                    });
                }
            });
            let temperatureService = null;
            temperatureService = eventTypes.find((x) => x.type == 0);
            let newEventTypes = JSON.parse(JSON.stringify(eventTypes));
            newEventTypes.forEach((type) => {
                let typeEvents = recordValues.filter((x) => x && x.eventType == type.slug);
                if (typeEvents.length > 0) {
                    getStatisticsTypeValues().forEach((statisticType) => {
                        let data = new Array();
                        timeMin = Math.min.apply(Math, data.map(function (o) {
                            return o.time;
                        }));
                        timeMax = Math.max.apply(Math, data.map(function (o) {
                            return o.time;
                        }));
                        let oneLineValues = typeEvents.filter((x) => x.key == statisticType);
                        if (oneLineValues.length <= 0)
                            return;
                        oneLineValues.forEach((event) => {
                            data.push({
                                time: event.datepoint * 1000,
                                value: event.value.Value == null ? null : event.value.Value
                            });
                        });
                        let borderValues = {
                            max_value: Math.max.apply(Math, data
                                .map(function (o) {
                                return o.value;
                            })
                                .filter((a) => a !== null)),
                            min_value: Math.min.apply(Math, data
                                .map(function (o) {
                                return o.value;
                            })
                                .filter((a) => a !== null)),
                            min_time: timeMin,
                            max_time: timeMax
                        };
                        let service = newEventTypes.find((x) => x.slug == type.slug);
                        let source = sourceGroupSources.find((x) => x.id == sourceId);
                        let loggerData = {
                            data: data,
                            metadata: borderValues,
                            serviceId: service.type,
                            name: `${source.name}`,
                            serviceName: `${service.name}`
                        };
                        convertedData.push(loggerData);
                    });
                }
            });
        });
        if (annotationsForSources.length > 0) {
            annotationsForSources.forEach((sourceAnnotation) => {
                if (sourceAnnotation.length > 0) {
                    let data = new Array();
                    sourceAnnotation.forEach((annotation) => {
                        data.push({
                            time: annotation.startDate * 1000,
                            toTime: annotation.endDate * 1000,
                            value: annotation.text
                        });
                    });
                    data = data.sort(ReportChartDataConverter.compare);
                    let borderValues = {
                        max_value: 0,
                        min_value: 0,
                        min_time: timeMin == 0 ? sourceAnnotation[0].startDate * 1000 : timeMin * 1000,
                        max_time: timeMax == 0 ? new Date().getTime() : timeMax * 1000
                    };
                    let loggerData = {
                        data: data,
                        metadata: borderValues,
                        serviceId: 101,
                        name: sourceGroupSources.find((x) => x.id == sourceAnnotation[0].sourceId).name,
                        serviceName: i18n.t(`services.annotation`)
                    };
                    convertedData.push(loggerData);
                }
            });
        }
        return convertedData;
    }
    static compare(a, b) {
        if (a.time < b.time) {
            return -1;
        }
        if (a.time > b.time) {
            return 1;
        }
        return 0;
    }
    getServiceDataByName(service) {
        switch (service) {
            case UnitNames.TEMPERATURE.name:
                return UnitNames.TEMPERATURE;
            case UnitNames.HUMIDITY.name:
                return UnitNames.HUMIDITY;
            case UnitNames.DEWPOINT.name:
                return UnitNames.DEWPOINT;
        }
    }
    createServices(reportRecords, eventTypes, annotationsForSources) {
        let services = new Array();
        Object.keys(reportRecords).forEach((sourceId) => {
            let recordValues = ReportRecord.getValues(reportRecords[sourceId]);
            let distinctServiceSlugs = ReportRecord.getServices(reportRecords[sourceId]);
            distinctServiceSlugs.forEach((serviceSlug) => {
                if (i18n.t(`services.${serviceSlug.toLowerCase()}`) != i18n.t(`services.dewpoint`)) {
                    let existService = services.find((x) => x.name == i18n.t(`services.${serviceSlug.toLowerCase()}`));
                    if (existService == null) {
                        let service = {
                            name: i18n.t(`services.${serviceSlug.toLowerCase()}`),
                            sign: recordValues.find((x) => x.eventType == serviceSlug).value.Unit,
                            id: eventTypes.find((x) => x.slug == serviceSlug).type
                        };
                        services.push(service);
                    }
                }
            });
        });
        if (annotationsForSources.length > 0) {
            annotationsForSources.forEach((sourceAnnotation) => {
                if (sourceAnnotation.length > 0) {
                    let existService = services.find((x) => x.name == i18n.t(`services.annotation`));
                    if (existService == null) {
                        let service = {
                            name: i18n.t(`services.annotation`),
                            sign: null,
                            id: 101
                        };
                        services.push(service);
                    }
                }
            });
        }
        services = services.sort((a, b) => (a.id > b.id ? 1 : -1));
        return services;
    }
}
