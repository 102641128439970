import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { latLng } from 'leaflet';
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from 'vue2-leaflet';
import SmartCitySensorRepository from '@/services/repository/SmartCitySensorRepository';
import EventType from '@/entities/EventType';
import bb, { line } from 'billboard.js';
import LoggedUserManager from '@/services/LoggedUserManager';
import { vxm } from '@/store/store.vuex';
import UserRepository from '@/services/repository/UserRepository';
import AuthenticationCalls, { isLoginData } from '@/services/AuthenticationCalls';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
import VueUtilities from '@/services/VueUtilities';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import EventTypeRepository from '@/services/repository/EventTypeRepository';
import AbilityManager from '@/services/permissions/AbilitiesManager';
import Subjects from '@/services/permissions/Subjects';
import { AdminAction } from '@/services/permissions/Actions';
var smartCitySensorRepository;
var vxDepartmentStore = vxm.departmentStore;
var userSettingsRepository;
var departmentRepository;
var authenticationCalls;
var userRepository;
let PublicSmartCityMap = class PublicSmartCityMap extends Vue {
    constructor() {
        super(...arguments);
        this.EventType = EventType;
        this.isLoading = false;
        this.latLng = latLng;
        this.chartHeight = 100;
        this.chartWidth = 400;
        this.mapObject = null;
        this.bounds = null;
        this.zoom = 13;
        this.center = latLng(48.61451, 19.14149);
        this.url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        this.attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
        this.currentZoom = 11.5;
        this.currentCenter = latLng(48.61451, 19.14149);
        this.mapOptions = {
            zoomSnap: 0.5
        };
        this.sensors = [];
        this.selectedDepartmentId = null;
        this.toUrl = null;
        this.password = null;
        this.username = null;
        this.loginButtonDisabled = false;
    }
    get isLoggedIn() {
        return LoggedUserManager.storageContainLoginCredentials(this.$cookies);
    }
    async created() {
        smartCitySensorRepository = new SmartCitySensorRepository(this);
        authenticationCalls = new AuthenticationCalls(this);
        userRepository = new UserRepository(this);
        this.$nextTick(() => {
            this.mapObject = this.$refs.smartCityMap.mapObject;
            this.bounds = this.mapObject.getBounds();
            this.loadData();
        });
    }
    async loadData() {
        this.sensors = await smartCitySensorRepository.getSmartCitySensorsPublic(this.bounds._southWest.lat, this.bounds._northEast.lat, this.bounds._southWest.lng, this.bounds._northEast.lng);
        this.isLoading = false;
    }
    async zoomUpdate(zoom) {
        this.bounds = this.mapObject.getBounds();
        this.currentZoom = zoom;
        this.isLoading = true;
        let newSensors = await smartCitySensorRepository.getSmartCitySensorsPublic(this.bounds._southWest.lat, this.bounds._northEast.lat, this.bounds._southWest.lng, this.bounds._northEast.lng);
        newSensors.forEach((sensor) => {
            if (this.sensors.find((x) => x.id === sensor.id) == null) {
                this.sensors.push(sensor);
            }
        });
        this.isLoading = false;
    }
    onMarkerClicked(sensor) {
        sensor.additionalProperties.popupVisibleOnMap = !sensor.additionalProperties.popupVisibleOnMap;
        if (sensor.additionalProperties.popupVisibleOnMap == true && Object.keys(sensor.data).length <= 0) {
            this.loadSensorData(sensor);
        }
    }
    createChartSelector(sensor) {
        return 'chart_' + sensor.id;
    }
    async loadSensorData(sensor) {
        let data = await smartCitySensorRepository.getSmartCitySensorDataPublic(sensor.id);
        sensor.data = data[sensor.id];
        let services = Object.keys(data[sensor.id]);
        let times = [];
        services.forEach((service) => {
            times = times.concat(data[sensor.id][service].data.map((x) => x[0]));
        });
        times = [...new Set(times)];
        let chartColumns = [];
        //prepare axis data
        chartColumns.push(['x'].concat(times.map((x) => new Date(x * 1000).toISOString())));
        services.forEach((service) => {
            chartColumns.push([service].concat(data[sensor.id][service].data.map((x) => x[1])));
        });
        this.$nextTick(() => {
            let ctx = this;
            let chart = bb.generate({
                size: {
                    height: this.chartHeight,
                    width: this.chartWidth
                },
                data: {
                    x: 'x',
                    xFormat: '%Y-%m-%dT%H:%M:%S.%MSZ',
                    columns: chartColumns,
                    type: line()
                },
                axis: {
                    y: {
                        tick: {
                            count: 3,
                            format: function (x) {
                                return x.toFixed(2);
                            }
                        }
                    },
                    x: {
                        tick: {
                            fit: false,
                            count: 5
                        },
                        type: 'timeseries'
                    }
                },
                point: {
                    show: false
                },
                legend: {
                    format: function (id) {
                        return ctx.$t('services.' + id.toLowerCase());
                    }
                },
                tooltip: {
                    format: {
                        title: function (d) {
                            return new Date(d).toLocaleString();
                        },
                        name: function (name, ratio, id) {
                            return ctx.$t('services.' + name.toLowerCase());
                        },
                        value: function (value, ratio, id) {
                            return value.toFixed(2) + sensor.data[id].unit.unit;
                        }
                    }
                },
                padding: {
                    left: 40
                },
                bindto: '#' + this.createChartSelector(sensor)
            });
        });
    }
    async submitCredentialsAsync() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.loginButtonDisabled = true;
                let loginData = await authenticationCalls.login(this.username, this.password);
                if (isLoginData(loginData)) {
                    const sessionPassword = loginData.session;
                    const apiUserFullName = loginData.apiUserFullName;
                    const authName = loginData.apiUserUsername;
                    LoggedUserManager.saveLoginData(this, authName, sessionPassword, apiUserFullName);
                    this.loginSucessfulAsync();
                }
                else {
                    let loginFailedResponse = loginData;
                    if (loginFailedResponse.code) {
                        switch (loginFailedResponse.code) {
                            case ApiResponseCodes.UNAUTHORIZED:
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.unauthorized').toString()}`);
                                break;
                            case ApiResponseCodes.WRONG_CREDENTIALS:
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.wrong_credentials').toString()}`);
                                break;
                            case ApiResponseCodes.ACCOUNT_DISABLED:
                                let reasonParts = loginFailedResponse.message.split(':');
                                reasonParts.shift();
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.acc_disabled', {
                                    reason: reasonParts.join(':')
                                }).toString()}`);
                                break;
                            case ApiResponseCodes.ACCOUNT_NOT_VERIFIED:
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.acc_not_verified')}`);
                                break;
                            default:
                                VueUtilities.openErrorToast(this, `${this.$t('component.login.failed').toString()}`);
                                break;
                        }
                    }
                    else {
                        VueUtilities.openErrorToast(this, `${this.$t('component.login.failed').toString()}`);
                    }
                }
                this.loginButtonDisabled = false;
            }
        });
    }
    async loginSucessfulAsync() {
        VueUtilities.openSuccessToast(this, this.$t('component.login.successful').toString());
        userSettingsRepository = UserSettingsRepository.getInstance(this, true);
        departmentRepository = new DepartmentRepository(this);
        let settings = await userSettingsRepository.loadUserSettings(true);
        // load departments to store
        let departments = (await departmentRepository.getDepartments()).getData().getData();
        let me = await userRepository.getCurrentUser();
        LoggedUserManager.saveIdOfLoggedUser(this, me.apiUserId);
        this.setDepartments(departments);
        if (departments.length > 0) {
            //checking and if user is not enabled in all department handle this situation
            if (this.getUserEnabledDepartments().length === 0) {
                this.$router
                    .push({
                    name: 'organizations',
                    params: {
                        lang: this.$route.params.lang
                    }
                })
                    .catch((err) => err);
            }
            else {
                // load selected and favorite department to store
                let preselectedDepartment = this.setSelectedDepartment(departments, settings.preferences.favoriteDepartmentId);
                vxDepartmentStore.selectedDepartmentRoleKey = preselectedDepartment.member.Role.Key;
                this.selectedDepartmentId = preselectedDepartment?.id;
                let user = await userRepository.getUser(me.apiUserId, this.selectedDepartmentId);
                /*TODO: Co ak user nebude mat ziadny department? Ak je to company admin,
                mali by sme ho navigovat na vytvorenie company.*/
                if (user.role.Key == ApiUserRoles.SYSTEM_ADMIN) {
                    let impersonatedAbilities = [Subjects.ALL + '.' + AdminAction];
                    vxDepartmentStore.selectedDepartmentPermissions = impersonatedAbilities;
                    this.$ability.update(new AbilityManager(impersonatedAbilities).getAbilities().rules);
                }
                else {
                    user.role.Permissions.push('Notifications.Company.Presets.Edit');
                    vxDepartmentStore.selectedDepartmentPermissions = user.role.Permissions;
                    this.$ability.update(new AbilityManager(this.getSelectedDepartmentPermissions()).getAbilities().rules);
                }
                EventTypeRepository.getInstance(this, true); // reload instance of repository singleton class
                if (ApiUserRoles.SYSTEM_ADMIN != vxDepartmentStore.selectedDepartmentRoleKey &&
                    ApiUserRoles.RESELLER_ADMIN != vxDepartmentStore.selectedDepartmentRoleKey) {
                    await this.$featuresManager.loadTier();
                }
                let res = await departmentRepository.getDepartment(this.selectedDepartmentId);
                let companySettings = res.settings;
                this.$i18n.locale = settings.language;
                this.$validator.localize(settings.language);
                if (ApiUserRoles.SYSTEM_ADMIN == vxDepartmentStore.selectedDepartmentRoleKey ||
                    ApiUserRoles.RESELLER_ADMIN == vxDepartmentStore.selectedDepartmentRoleKey) {
                    this.$router
                        .push({
                        name: 'adminCompanies',
                        params: { lang: this.$route.params.lang }
                    })
                        .catch((err) => err);
                }
                else if ((ApiUserRoles.COMPANY_OWNER == vxDepartmentStore.selectedDepartmentRoleKey ||
                    ApiUserRoles.COMPANY_ADMIN == vxDepartmentStore.selectedDepartmentRoleKey) &&
                    companySettings.setupRequired != false) {
                    this.$router
                        .push({
                        name: 'wizard',
                        params: {
                            departmentId: this.selectedDepartmentId,
                            lang: this.$route.params.lang
                        }
                    })
                        .catch((err) => err);
                }
                else if (this.toUrl) {
                    this.$router
                        .push({
                        path: this.toUrl,
                        params: {
                            departmentId: this.selectedDepartmentId,
                            lang: this.$route.params.lang
                        }
                    })
                        .catch((err) => err);
                }
                else {
                    this.$router
                        .push({
                        name: 'dashboard',
                        params: {
                            departmentId: this.selectedDepartmentId,
                            lang: this.$route.params.lang
                        }
                    })
                        .catch((err) => err);
                }
            }
        }
        else {
            this.$router
                .push({
                name: 'organizations',
                params: {
                    lang: this.$route.params.lang
                }
            })
                .catch((err) => err);
        }
    }
    setDepartments(value) {
        vxDepartmentStore.departments = value;
    }
    getSelectedDepartmentPermissions() {
        return vxDepartmentStore.selectedDepartmentPermissions;
    }
    // Return user enabled departments
    getUserEnabledDepartments() {
        return this.getDepartments().filter((department) => department.member.Enabled);
    }
    getDepartments() {
        return vxDepartmentStore.departments;
    }
    /**
     * Function which initializes selected and favorite department id in store
     * If user has favorite deparment in preferences, it's also his currently selected
     * If user hasn't favorite department, his currently selected department is first from list of departments
     * @param departments
     * @param favoriteDepartmentId
     * @returns currently selected department
     */
    setSelectedDepartment(departments, favoriteDepartmentId) {
        //Checking if the user is enabled in their favorite department
        let userEnabledFavDepartment = departments.find((x) => x.id === favoriteDepartmentId && x.member.Enabled);
        let userEnabledAdminDepartment = this.getUserEnabledAdminDepartment();
        //Choose one department from user enabled departments
        if (userEnabledAdminDepartment) {
            vxDepartmentStore.selectedDepartment = userEnabledAdminDepartment;
        }
        else if (userEnabledFavDepartment) {
            vxDepartmentStore.setSelectedDepartmentById(userEnabledFavDepartment.id);
            vxDepartmentStore.setFavoriteDepartmentById(userEnabledFavDepartment.id);
        }
        else {
            vxDepartmentStore.selectedDepartment = this.getUserEnabledDepartments()[0];
        }
        return this.getSelectedDepartment();
    }
    getSelectedDepartment() {
        return vxDepartmentStore.selectedDepartment;
    }
    // Return user enabled department
    getUserEnabledAdminDepartment() {
        return this.getDepartments().find((department) => department.member.Enabled && department.member.Role.Key === ApiUserRoles.SYSTEM_ADMIN);
    }
};
PublicSmartCityMap = __decorate([
    Component({
        components: { LMap, LTileLayer, LMarker, LPopup, LTooltip }
    })
], PublicSmartCityMap);
export default PublicSmartCityMap;
