import { __decorate } from "tslib";
import { parse } from 'svg-parser';
import * as d3 from 'd3';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ThermoMapService from '@/services/thermo-mapping/ThermoMapService';
import { Corner, Door, LayoutData, Point, Room, Sensor } from '@/services/thermo-mapping/model/Room';
import { Buffer } from 'buffer';
import VueUtilities from '@/services/VueUtilities';
const PPM = 5;
let SCALE = 1;
const ENTITY_NAME = 'source';
let ThermalMap = class ThermalMap extends Vue {
    constructor() {
        super(...arguments);
        this.DEFAULT_NAME = this.$t('component.thermal_map.default_name');
        this.isUploading = false;
        this.image = new Image();
        this.imageFile = null;
        this.imageConfirmed = false;
        this.currentImage = null;
        this.thermoMapService = new ThermoMapService();
        this.layoutData = new LayoutData();
        this.boundingBox = null;
        this.thermoMapName = this.DEFAULT_NAME;
        this.imageData = null;
        this.imageWidth = null;
        this.imageHeight = null;
        this.dateRange = [];
        this.canvasWidth = 1600;
        this.canvasHeight = 1100;
        this.link = null;
        this.colorRange = null;
        this.isReading = false;
        this.renderSettings = {
            showFlooplan: true,
            showPointMap: false,
            showDistanceMap: false,
            showRoomWalls: 'svg',
            showSensors: true,
            showMap: 'matrix',
            mapOpacity: 0.7,
            minTemp: 20,
            maxTemp: 30
        };
    }
    updateRenderSettings(e) {
        this.renderMap();
    }
    created() {
        this.prefillConfig();
    }
    async mounted() {
        this.currentImage = this.selectedMapImage;
        this.$nextTick(() => {
            this.layoutData = new LayoutData();
            if (this.currentImage) {
                this.canvasWidth = document.getElementById('svgContainer').offsetWidth;
                this.processSvgFloorplan(this.currentImage);
                this.prepareLayoutData();
            }
        });
    }
    prefillConfig() {
        if (this.selectedMap) {
            this.thermoMapName = this.selectedMap.name;
            this.dateRange = [new Date(this.selectedMap.from * 1000), new Date(this.selectedMap.to * 1000)];
            this.renderSettings.minTemp = this.selectedMap.minValue || 20;
            this.renderSettings.maxTemp = this.selectedMap.maxValue || 30;
        }
        else {
            let weekBefore = new Date();
            weekBefore.setDate(weekBefore.getDate() - 7);
            this.dateRange = [weekBefore, new Date()];
        }
    }
    saveImage() {
        this.imageConfirmed = true;
        this.imageFile.text().then((svgText) => {
            this.currentImage = svgText;
            this.canvasWidth = document.getElementById('svgContainer').offsetWidth;
            this.processSvgFloorplan(this.currentImage);
            this.prepareLayoutData();
        });
    }
    generateSvgLink() {
        //get svg element.
        var svg = document.getElementById('svgElement');
        //get svg source.
        var serializer = new XMLSerializer();
        var source = serializer.serializeToString(svg);
        //add name spaces.
        if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }
        //add xml declaration
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
        //convert svg source to URI data scheme.
        var url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);
        this.link = url;
    }
    async prepareLayoutData() {
        this.boundingBox = this.thermoMapService.calculateBoundingBox(this.layoutData.rooms, PPM);
        if (this.selectedMap) {
            this.layoutData.sensors.forEach((s) => {
                s.assignedSourceId = this.selectedMap.locations.find((x) => x.ElementId === s.id)?.EntityId || null;
                let statForSource = this.statistics[s.assignedSourceId];
                //  console.log(this.statistics);
                //find temperature value
                let tempStats = statForSource?.find((x) => x.service == 'temperature');
                s.t = tempStats?.avg;
            });
            this.layoutData.rooms.forEach((s) => {
                s.sensors = s.sensors.filter((x) => x.t !== undefined);
            });
        }
        this.layoutData.rooms.forEach((s) => {
            s.valueMap = this.thermoMapService.initializeValueMapForRoom(s, this.layoutData.sensors);
        });
        this.layoutData.sensors.forEach((s) => {
            let room = this.layoutData.rooms.find((r) => r.isInsideRoom(s));
            s.distanceMap = this.thermoMapService.getSensorDistanceMapForRoom(s, room);
        });
        this.layoutData.rooms.forEach((room) => {
            let connectedSensors = room
                .getConnectedRooms()
                .map((r) => r.sensors)
                .flat();
            this.thermoMapService.calculateTemperatureMapForRoom(room, connectedSensors);
        });
        this.renderMap();
    }
    hasDuplicatedSourceAssigned(sensorId) {
        let sourceId = this.layoutData.sensors.find((x) => x.id === sensorId)?.assignedSourceId;
        if (sourceId && this.layoutData.sensors.filter((x) => x.assignedSourceId == sourceId)?.length > 1)
            return true;
        else
            return false;
    }
    saveConfigurationAndLoadData() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                let sensors = [];
                this.layoutData.sensors.forEach((sensor) => {
                    if (sensor.assignedSourceId) {
                        sensors.push({ ElementId: sensor.id, EntityId: sensor.assignedSourceId, Entity: ENTITY_NAME });
                    }
                });
                if (!this.selectedMap) {
                    let mapConfig = {
                        Map: this.imageFile,
                        Data: JSON.stringify(sensors),
                        Name: this.thermoMapName,
                        From: this.dateTimeManager.formatStartDateForReports(this.dateRange[0]).text,
                        To: this.dateTimeManager.formatStartDateForReports(this.dateRange[1]).text,
                        MaxValue: this.renderSettings.maxTemp,
                        MinValue: this.renderSettings.minTemp
                    };
                    this.$emit('createThermoMap', mapConfig);
                }
                else {
                    let mapConfig = {
                        Data: JSON.stringify(sensors),
                        Name: this.thermoMapName,
                        From: this.dateTimeManager.formatStartDateForReports(this.dateRange[0]).text,
                        To: this.dateTimeManager.formatStartDateForReports(this.dateRange[1]).text,
                        MaxValue: this.renderSettings.maxTemp,
                        MinValue: this.renderSettings.minTemp
                    };
                    this.$emit('updateThermoMap', mapConfig, this.selectedMap.id);
                }
            }
        });
    }
    async setImage(file) {
        this.isReading = true;
        this.image = new Image();
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener('load', async () => {
            await new Promise((resolve, reject) => {
                this.image.src = reader.result;
                this.image.onload = resolve;
                this.image.onerror = reject;
            });
            // Getting and apply percentage for creating responsive image
            this.image.setAttribute('crossOrigin', 'anonymous');
            //   let filename: any = document.getElementById('fileInput');
            //    this.imageFile = dataUrlToFile(this.image.src, filename.files[0].name, "image/svg");
            this.isUploading = true;
            this.isReading = false;
        });
    }
    dataUrlToFile(dataUrl, filename) {
        const arr = dataUrl.split(',');
        if (arr.length < 2) {
            return undefined;
        }
        const mimeArr = arr[0].match(/:(.*?);/);
        if (!mimeArr || mimeArr.length < 2) {
            return undefined;
        }
        const mime = mimeArr[1];
        const buff = Buffer.from(arr[1], 'base64');
        return new File([buff], filename, { type: mime });
    }
    async processSvgFloorplan(svgString) {
        let svgRoot = parse(svgString).children.find((e) => e.tagName == 'svg');
        let svgWidth = svgRoot.properties.width;
        let svgHeight = svgRoot.properties.height;
        this.canvasHeight = svgHeight;
        this.canvasRatio = svgWidth / this.canvasWidth;
        let imageElement = svgRoot.children.find((e) => e.properties.id == 'image');
        let roomsElement = svgRoot.children.find((e) => e.properties.id == 'rooms');
        let sensorsElement = svgRoot.children.find((e) => e.properties.id == 'sensors');
        let doorsElement = svgRoot.children.find((e) => e.properties.id == 'doors');
        if (!imageElement) {
            VueUtilities.openErrorToast(this, this.$t('component.thermal_map.image_not_valid'));
            this.isUploading = false;
            this.image = new Image();
            this.imageFile = null;
            this.imageConfirmed = false;
            this.currentImage = null;
            return;
        }
        this.imageWidth = imageElement.children.find((e) => e.tagName == 'image').properties['width'];
        this.imageHeight = imageElement.children.find((e) => e.tagName == 'image').properties['height'];
        this.imageRatio = this.imageWidth / this.imageHeight;
        this.imageData = imageElement.children.find((e) => e.tagName == 'image').properties['xlink:href'];
        this.imageData = this.imageData.replaceAll('&#10;', '');
        this.layoutData.rooms = [];
        roomsElement.children.forEach((roomEl) => {
            let room = new Room();
            room.id = roomEl.properties.id;
            var corners = this.convertSvgToAbsoluteCoordinates(roomEl);
            corners.forEach((cornerData) => {
                let corner = new Corner(cornerData[0] / this.canvasRatio, cornerData[1] / this.canvasRatio, PPM);
                room.corners.push(corner);
            });
            room.boundingBox = this.thermoMapService.calculateBoundingBoxForRoom(room, PPM);
            room.corners.push(new Corner(corners[0][0] / this.canvasRatio, corners[0][1] / this.canvasRatio, PPM));
            room.identifyConvexCorners();
            this.layoutData.rooms.push(room);
        });
        this.layoutData.sensors = [];
        sensorsElement.children.forEach((sensorEl, index) => {
            if (sensorEl.tagName == 'circle') {
                let sensor = new Sensor(sensorEl.properties.id, sensorEl.properties.cx / this.canvasRatio, sensorEl.properties.cy / this.canvasRatio, 0, PPM, index);
                this.layoutData.sensors.push(sensor);
                let room = this.layoutData.rooms.find((r) => r.isInsideRoom({ x: sensor.absX, y: sensor.absY }, true));
                if (room == null)
                    return;
                sensor.room = room;
                room.sensors.push(sensor);
            }
        });
        this.layoutData.doors = [];
        if (doorsElement) {
            doorsElement.children.forEach((doorEl) => {
                if (doorEl.tagName == 'rect') {
                    let corners = this.convertRectToAbsolutPath(doorEl).map((c) => new Corner(c[0] / this.canvasRatio, c[1] / this.canvasRatio, PPM));
                    let door = new Door();
                    door.id = doorEl.properties.id;
                    door.rawCorners = corners;
                    let room1 = this.layoutData.rooms.find((r) => r.isInsideRoom(door.rawCorners[0]));
                    let room2 = this.layoutData.rooms.find((r) => r.isInsideRoom(door.rawCorners[1]));
                    let room3 = this.layoutData.rooms.find((r) => r.isInsideRoom(door.rawCorners[2]));
                    let room4 = this.layoutData.rooms.find((r) => r.isInsideRoom(door.rawCorners[3]));
                    // If any corner is outside or room, skip it
                    if (room1 == null || room2 == null || room3 == null || room4 == null)
                        return;
                    if ((room1 == room2 && room3 == room4 && room1 != room3) ||
                        (room1 == room4 && room2 == room3 && room1 != room3)) {
                        door.room1 = room1;
                        door.room2 = room3;
                        room1.doors.push(door);
                        room2.doors.push(door);
                    }
                    let cx = door.rawCorners[0].absX + (door.rawCorners[2].absX - door.rawCorners[0].absX) / 2;
                    let cy = door.rawCorners[0].absY + (door.rawCorners[2].absY - door.rawCorners[0].absY) / 2;
                    door.center = new Point(Math.floor(cx / PPM), Math.floor(cy / PPM));
                    door.center.absX = cx;
                    door.center.absY = cy;
                    this.layoutData.doors.push(door);
                }
            });
        }
    }
    async renderMap() {
        let svg = d3
            .select('svg')
            .attr('width', this.canvasWidth)
            .attr('height', this.canvasWidth / this.imageRatio + 50);
        svg.selectAll('*').remove();
        if (this.renderSettings.showFlooplan)
            this.renderFloorplan(svg);
        if (this.renderSettings.showPointMap)
            this.renderPointMap(svg);
        if (this.renderSettings.showDistanceMap) {
            let s = this.layoutData.sensors.find((s) => s.id == this.renderSettings.sensorDistanceMap);
            if (s != null)
                this.renderDistanceMap(svg, s);
        }
        if (this.renderSettings.showMap != 'no') {
            this.layoutData.rooms.forEach((r) => {
                if (this.statistics) {
                    this.renderRoomMap(svg, r);
                }
            });
        }
        if (this.renderSettings.showRoomWalls != 'no')
            this.renderRooms(svg);
        if (this.renderSettings.showSensors)
            this.renderSensors(svg);
        if (this.selectedMap) {
            this.renderLegend(svg);
            this.generateSvgLink();
        }
    }
    renderRoomMap(svg, r) {
        let arr = [];
        for (let y = 0; y < r.boundingBox.maxY; y++) {
            for (let x = 0; x < r.boundingBox.maxX; x++) {
                if (r.valueMap[x] != null && r.valueMap[x][y] && r.valueMap[x][y].v != null)
                    arr.push(r.valueMap[x][y].v);
                else
                    arr.push(null);
            }
        }
        let MIN = this.renderSettings.minTemp;
        let MAX = this.renderSettings.maxTemp;
        this.colorRange = d3.scaleSequential(d3.interpolateTurbo).domain([MIN, MAX]).nice();
        var temperatureMap = svg.append('g');
        const path = d3.geoPath().projection(d3.geoIdentity().scale(PPM));
        const contours = d3.contours().size([r.boundingBox.maxX, r.boundingBox.maxY]).smooth(false);
        let maskId = 'roomMask_' + r.id;
        var defs = svg.select('defs');
        if (defs.empty())
            defs = svg.append('defs');
        var mask = defs.append('mask').attr('id', maskId);
        mask
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', this.canvasWidth)
            .attr('height', this.canvasHeight)
            .style('fill', 'black')
            .style('opacity', 1);
        mask
            .append('path')
            .datum(r.corners)
            .attr('fill', 'white')
            .attr('d', d3.line().x((d) => d.absX).y((d) => d.absY));
        temperatureMap.attr('mask', `url(#${maskId})`);
        // Render temperature map as contour paths
        if (this.renderSettings.showMap == 'contours') {
            temperatureMap
                .append('g')
                .attr('opacity', this.renderSettings.mapOpacity)
                .selectAll()
                .data(this.colorRange.ticks(500))
                .join('path')
                .attr('d', (d) => path(contours.contour(arr, d)))
                .attr('fill', this.colorRange);
        }
        else {
            // Render temperature map as 2D matrix
            for (let y = r.boundingBox.minY; y < r.boundingBox.maxY + 1; y++) {
                for (let x = r.boundingBox.minX; x < r.boundingBox.maxX + 1; x++) {
                    if (r.valueMap[x] != null && r.valueMap[x][y] != null && r.valueMap[x][y].v != null)
                        if (r.valueMap[x][y].type != 'door')
                            this.renderPoint(temperatureMap, x, y, this.colorRange(r.valueMap[x][y].v));
                }
            }
        }
    }
    renderPointMap(svg) {
        let dotGroup = svg.append('g');
        for (let y = this.boundingBox.minY; y < this.boundingBox.maxY + 1; y++) {
            for (let x = this.boundingBox.minX; x < this.boundingBox.maxX + 1; x++) {
                dotGroup
                    .append('circle')
                    .attr('cx', x * PPM)
                    .attr('cy', y * PPM)
                    .attr('r', 1)
                    .style('fill', 'black');
            }
        }
    }
    renderDistanceMap(svg, s) {
        var distanceMap = svg.append('g');
        let minVal = d3.min(s.distanceMap.flat());
        let maxVal = d3.max(s.distanceMap.flat());
        const color = d3.scaleSequential(d3.interpolateBlues).domain([maxVal, minVal]).nice();
        for (let y = this.boundingBox.minY; y < this.boundingBox.maxY + 1; y++) {
            for (let x = this.boundingBox.minX; x < this.boundingBox.maxX + 1; x++) {
                if (s.distanceMap[x] != null && s.distanceMap[x][y] != null && s.distanceMap[x][y] >= 0)
                    distanceMap
                        .append('rect')
                        .attr('x', x * PPM - PPM * 0.5)
                        .attr('y', y * PPM - PPM * 0.5)
                        .attr('width', SCALE * PPM)
                        .attr('height', SCALE * PPM)
                        .attr('fill', color(s.distanceMap[x][y]))
                        .attr('opacity', this.renderSettings.mapOpacity)
                        .attr('stroke', 'none');
            }
        }
    }
    renderPoint(rootElement, x, y, color) {
        switch (this.renderSettings.showMap) {
            case 'matrix':
                // Rectangles
                rootElement
                    .append('rect')
                    .attr('x', x * PPM - PPM * 0.5)
                    .attr('y', y * PPM - PPM * 0.5)
                    .attr('width', SCALE * PPM)
                    .attr('height', SCALE * PPM)
                    .attr('fill', color)
                    .attr('opacity', this.renderSettings.mapOpacity)
                    .attr('stroke', 'none');
                break;
            case 'matrix-spaced':
                // Rectangles with spacing
                rootElement
                    .append('rect')
                    .attr('x', x * PPM - PPM * 0.5 + PPM * 0.05)
                    .attr('y', y * PPM - PPM * 0.5 + PPM * 0.05)
                    .attr('width', SCALE * PPM * 0.9)
                    .attr('height', SCALE * PPM * 0.9)
                    .attr('fill', color)
                    .attr('opacity', this.renderSettings.mapOpacity)
                    .attr('stroke', 'none');
                break;
            case 'matrix-dots':
                // As circle
                rootElement
                    .append('circle')
                    .attr('cx', x * PPM)
                    .attr('cy', y * PPM)
                    .attr('r', 2)
                    .attr('fill', color)
                    .attr('opacity', this.renderSettings.mapOpacity)
                    .attr('stroke', 'none');
                break;
        }
    }
    renderSensors(svg) {
        let sensorGroup = svg.append('g');
        this.layoutData.sensors.forEach((sensor) => {
            if (this.renderSettings?.showDistanceMap != false && sensor.id == this.renderSettings?.sensorDistanceMap) {
                sensorGroup
                    .append('circle')
                    .attr('fill', 'red')
                    .attr('stroke', 'black')
                    .attr('stroke-width', SCALE / 2)
                    .attr('cx', sensor.x * PPM)
                    .attr('cy', sensor.y * PPM)
                    .attr('r', 10);
                //Add text to circles
                sensorGroup
                    .append('text')
                    .attr('x', sensor.x * PPM)
                    .attr('y', sensor.y * PPM)
                    .attr('dy', '0.35em') // Align text vertically to the middle
                    .attr('text-anchor', 'middle') // Align text horizontally to the middle
                    .attr('font-size', '14px') // Set text size as needed
                    .attr('fill', 'black') // Set text color
                    .text(sensor.index + 1);
            }
            else {
                sensorGroup
                    .append('circle')
                    .attr('fill', 'yellow')
                    .attr('stroke', 'black')
                    .attr('stroke-width', SCALE / 2)
                    .attr('cx', sensor.x * PPM)
                    .attr('cy', sensor.y * PPM)
                    .attr('r', 10);
                //Add text to circles
                sensorGroup
                    .append('text')
                    .attr('x', sensor.x * PPM)
                    .attr('y', sensor.y * PPM)
                    .attr('dy', '0.35em') // Align text vertically to the middle
                    .attr('text-anchor', 'middle') // Align text horizontally to the middle
                    .attr('font-size', '14px') // Set text size as needed
                    .attr('fill', 'black') // Set text color
                    .text(sensor.index + 1);
            }
        });
    }
    renderRooms(svg) {
        let roomGroup = svg.append('g');
        this.layoutData.rooms.forEach((room) => {
            // Room walls on 2D matrix
            if (this.renderSettings.showRoomWalls == 'matrix')
                roomGroup
                    .append('path')
                    .datum(room.corners)
                    .attr('fill', 'none')
                    .attr('stroke', 'red')
                    .attr('stroke-width', 2)
                    .attr('d', d3.line().x((d) => d.x * PPM).y((d) => d.y * PPM));
            // Room walls on absolute coordinates from SVG
            if (this.renderSettings.showRoomWalls == 'svg')
                roomGroup
                    .append('path')
                    .datum(room.corners)
                    .attr('fill', 'none')
                    .attr('stroke', 'green')
                    .attr('stroke-width', 2)
                    .attr('d', d3.line().x((d) => d.absX).y((d) => d.absY));
            if (this.renderSettings.showRoomWalls == 'raster') {
                let roomWall = roomGroup.append('g');
                for (let y = room.boundingBox.minY; y < room.boundingBox.maxY + 1; y++) {
                    for (let x = room.boundingBox.minX; x < room.boundingBox.maxX + 1; x++) {
                        if (room.valueMap[x] != null && room.valueMap[x][y] != null)
                            if (room.valueMap[x][y].type == 'wall')
                                roomWall
                                    .append('rect')
                                    .attr('x', x * PPM - PPM * 0.5)
                                    .attr('y', y * PPM - PPM * 0.5)
                                    .attr('width', SCALE * PPM)
                                    .attr('height', SCALE * PPM)
                                    .attr('fill', 'black')
                                    .attr('opacity', this.renderSettings.mapOpacity)
                                    .attr('stroke', 'none');
                    }
                }
            }
        });
    }
    renderFloorplan(svg) {
        svg
            .append('g')
            .append('image')
            .attr('x', 0)
            .attr('y', 0)
            .attr('height', this.canvasWidth / this.imageRatio)
            .attr('width', this.canvasWidth)
            .attr('xlink:href', this.imageData);
    }
    renderLegend(svg) {
        let colors = [];
        let ticks = this.colorRange.ticks(10);
        ticks.forEach((tick) => {
            colors.push(this.colorRange(tick));
        });
        var grad = svg
            .append('defs')
            .append('linearGradient')
            .attr('id', 'grad')
            .attr('x1', '0%')
            .attr('x2', '100%')
            .attr('y1', '0%')
            .attr('y2', '0%');
        grad
            .selectAll('stop')
            .data(colors)
            .enter()
            .append('stop')
            .style('stop-color', function (d) {
            return d;
        })
            .attr('offset', function (d, i) {
            return 100 * (i / (colors.length - 1)) + '%';
        });
        svg
            .append('rect')
            .attr('x', this.canvasWidth * 0.5 - 100)
            .attr('y', this.canvasWidth / this.imageRatio + 5)
            .attr('width', 200)
            .attr('height', 20)
            .style('fill', 'url(#grad)');
        svg
            .append('text')
            .attr('x', this.canvasWidth * 0.5 - 100)
            .attr('y', this.canvasWidth / this.imageRatio + 35)
            .attr('dy', '.25em')
            .text(this.renderSettings.minTemp + '°C');
        svg
            .append('text')
            .attr('x', this.canvasWidth * 0.5 + 70)
            .attr('y', this.canvasWidth / this.imageRatio + 35)
            .attr('dy', '.25em')
            .text(this.renderSettings.maxTemp + '°C');
    }
    arrayBufferToString(buffer) {
        const decoder = new TextDecoder();
        const str = decoder.decode(buffer);
        return str;
    }
    convertSvgToAbsoluteCoordinates(element) {
        if (element.tagName == 'path')
            return this.convertPathToAbsolutePath(element);
        if (element.tagName == 'rect')
            return this.convertRectToAbsolutPath(element);
        return [];
    }
    convertRectToAbsolutPath(element) {
        var x = element.properties.x;
        var y = element.properties.y;
        var w = element.properties.width;
        var h = element.properties.height;
        var path = [
            [x, y],
            [x + w, y],
            [x + w, y + h],
            [x, y + h]
        ];
        return path;
    }
    convertPathToAbsolutePath(element) {
        let commands = element.properties.d.match(/[a-z][^a-z]*/gi);
        let absolutePath = [];
        let currentX = 0, currentY = 0;
        let startX = 0, startY = 0;
        commands.forEach((cmd) => {
            let type = cmd[0];
            let args = cmd
                .slice(1)
                .trim()
                .split(/[\s,]+/)
                .map(parseFloat);
            switch (type) {
                case 'M':
                    currentX = args[0];
                    currentY = args[1];
                    absolutePath.push([currentX, currentY]);
                    startX = currentX;
                    startY = currentY;
                    break;
                case 'm':
                    for (let i = 0; i < args.length; i += 2) {
                        currentX += args[i];
                        currentY += args[i + 1];
                        absolutePath.push([currentX, currentY]);
                        startX = currentX;
                        startY = currentY;
                    }
                    break;
                case 'L':
                    for (let i = 0; i < args.length; i += 2) {
                        currentX = args[i];
                        currentY = args[i + 1];
                        absolutePath.push([currentX, currentY]);
                    }
                    break;
                case 'l':
                    for (let i = 0; i < args.length; i += 2) {
                        currentX += args[i];
                        currentY += args[i + 1];
                        absolutePath.push([currentX, currentY]);
                    }
                    break;
                case 'H':
                    args.forEach((arg) => {
                        currentX = arg;
                        absolutePath.push([currentX, currentY]);
                    });
                    break;
                case 'h':
                    args.forEach((arg) => {
                        currentX += arg;
                        absolutePath.push([currentX, currentY]);
                    });
                    break;
                case 'V':
                    args.forEach((arg) => {
                        currentY = arg;
                        absolutePath.push([currentX, currentY]);
                    });
                    break;
                case 'v':
                    args.forEach((arg) => {
                        currentY += arg;
                        absolutePath.push([currentX, currentY]);
                    });
                    break;
                case 'Z':
                case 'z':
                    currentX = startX;
                    currentY = startY;
                    break;
                default:
                    throw new Error(`Unsupported path command: ${type}`);
            }
        });
        return absolutePath;
    }
};
__decorate([
    Prop({ type: Array })
], ThermalMap.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array })
], ThermalMap.prototype, "thermoMaps", void 0);
__decorate([
    Prop({ type: Object })
], ThermalMap.prototype, "selectedMap", void 0);
__decorate([
    Prop({ type: String })
], ThermalMap.prototype, "selectedMapImage", void 0);
__decorate([
    Prop({ type: Object })
], ThermalMap.prototype, "dateTimeManager", void 0);
__decorate([
    Prop({ type: Object })
], ThermalMap.prototype, "statistics", void 0);
ThermalMap = __decorate([
    Component({ components: {} })
], ThermalMap);
export default ThermalMap;
