var Subjects;
(function (Subjects) {
    Subjects["ALL"] = "all";
    Subjects["USER"] = "User";
    Subjects["LOGGER"] = "Loggers";
    Subjects["SOURCE"] = "Source";
    Subjects["SOURCE_GROUP"] = "SourceGroup";
    Subjects["DEVICE"] = "Device";
    Subjects["DEVICE_GROUP"] = "DeviceGroup";
    Subjects["MEASURED_DATA_READ"] = "Events";
    Subjects["MEASURED_DATA_VALIDATE"] = "Validation";
    Subjects["VALIDATE_COMPANY"] = "Validation.Company";
    Subjects["REPORTS"] = "Reports";
    Subjects["SALES"] = "Sales";
    Subjects["COMPANY"] = "Company";
    Subjects["USERS"] = "Users";
    Subjects["USERS_ROLE"] = "Users.Role";
    Subjects["PERMISSIONS"] = "Permissions";
    Subjects["ROLES"] = "Roles";
    Subjects["ADMIN"] = "Admin";
    Subjects["RESELLER"] = "Reseller";
    Subjects["NOTIFICATION"] = "Notifications";
    Subjects["NOTIFICATION_COMPANY"] = "Notifications.Company";
    Subjects["FEATURES"] = "Features";
    Subjects["DASHBOARD"] = "Dashboard";
    Subjects["SYSTEM"] = "System";
    Subjects["ISSUES"] = "Issues";
    Subjects["DEPARTMENT_PRESETS"] = "Notifications.Company.Presets";
    Subjects["ADMIN_SYSTEM_SETTINGS"] = "Admin.System.Settings";
    Subjects["ADMIN_SYSTEM_TASKS"] = "Admin.System.Tasks";
    Subjects["ADMIN_DEPARTMENTS"] = "Admin.Departments";
    Subjects["ADMIN_DEPARTMENTS_SETTINGS"] = "Admin.Departments.Settings";
    Subjects["ADMIN_DEPARTMENTS_USERS"] = "Admin.Departments.Users";
    Subjects["ADMIN_GATEWAYS"] = "Admin.Gateways";
    Subjects["ADMIN_GATEWAYS_UPDATES"] = "Admin.Gateways.Updates";
    Subjects["ADMIN_GATEWAYS_CREDENTIALS"] = "Admin.Gateways.Credentials";
    Subjects["ADMIN_GATEWAYS_COMMANDS"] = "Admin.Gateways.Commands";
    Subjects["ADMIN_GATEWAYS_FIRMWARES"] = "Admin.Gateways.Firmwares";
    Subjects["ADMIN_LOGGERS"] = "Admin.Loggers";
    Subjects["ADMIN_COMPANYLOGGERS"] = "Admin.CompanyLoggers";
    Subjects["ADMIN_LOGGERS_CALIBRATION"] = "Admin.Loggers.Calibration";
    Subjects["ADMIN_LOGGERS_COMMANDS"] = "Admin.Loggers.Commands";
    Subjects["ADMIN_LOGGERS_CREDENTIALS"] = "Admin.Loggers.Credentials";
    Subjects["ADMIN_NOTIFICATIONS"] = "Admin.Notifications";
    Subjects["ADMIN_NOTIFICATIONS_SETTINGS"] = "Admin.Notifications.Settings";
    Subjects["ADMIN_NOTIFICATIONS_USER"] = "Admin.Notifications.User";
    Subjects["ADMIN_PRESETS"] = "Admin.Notifications.Presets";
    Subjects["ADMIN_FEEDBACK"] = "Admin.Feedback";
    Subjects["ADMIN_USERS"] = "Admin.Users";
    Subjects["ADMIN_DEPARTMENTS_SOURCES"] = "Admin.Departments.Sources";
    Subjects["ADMIN_DEPARTMENTS_SOURCES_GROUPS"] = "Admin.Departments.Sources.Groups";
    Subjects["ADMIN_DEPARTMENTS_SOURCES_VALIDATION_USER"] = "Admin.Departments.Sources.Validation.User";
    Subjects["ADMIN_TIERS"] = "Admin.Tiers";
    Subjects["ADMIN_TIERS_REQUESTS"] = "Admin.Tiers.Requests";
    Subjects["ADMIN_DEPARTMENTS_TIERS"] = "Admin.Departments.Tiers";
    Subjects["ADMIN_USERS_PHONE"] = "Admin.Users.Phone";
    Subjects["ADMIN_USERS_DEPARTMENTS"] = "Admin.Users.Departments";
    Subjects["ADMIN_USERS_SETTINGS"] = "Admin.Users.Settings";
    Subjects["ADMIN_RESELLER"] = "Admin.Reseller";
    Subjects["ADMIN_THERMOMAP"] = "Admin.Thermomap";
})(Subjects || (Subjects = {}));
export default Subjects;
