import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import ResendVerificationModal from '@/views/components/profile/ResendVerificationModal.vue';
import VerifyPhoneNumberModal from '@/views/components/profile/VerifyPhoneNumberModal.vue';
import UserRepository from '@/services/repository/UserRepository';
import VueUtilities from '@/services/VueUtilities';
import ChangePasswordModal from '@/views/components/profile/ChangePasswordModal.vue';
import LoggedUserManager from '@/services/LoggedUserManager';
import DeleteAccountModal from '@/views/components/profile/DeleteAccountModal.vue';
var userRepository;
let UserInfoComponent = class UserInfoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.isEditing = false;
        this.newName = null;
        this.isVerificationModalActive = false;
        this.isPhoneVerificationModalActive = false;
        this.isChangePasswordModalActive = false;
        this.isDeleteAccountModalActive = false;
    }
    get hasPhoneNumber() {
        if (this.currentUser.detailedInfo.PhoneNumber) {
            return true;
        }
        else
            return false;
    }
    get nameChanged() {
        return this.newName != this.currentUser.fullName;
    }
    get isLoggedFromAdmin() {
        return LoggedUserManager.isAdmin(this);
    }
    created() {
        userRepository = new UserRepository(this);
        this.newName = this.currentUser.fullName;
        if (this.$route.query.isPhoneVerify == 'true') {
            this.openPhoneVerificationModal();
        }
    }
    editUser() {
        this.isEditing = !this.isEditing;
    }
    update() {
        this.updateName();
        this.isEditing = false;
    }
    updateName() {
        this.$emit('updateUser', this.newName);
    }
    openVerificationModal() {
        this.isVerificationModalActive = true;
    }
    openPhoneVerificationModal() {
        this.isPhoneVerificationModalActive = true;
    }
    closeModal() {
        this.isVerificationModalActive = false;
        this.isPhoneVerificationModalActive = false;
        if (!this.isPhoneVerificationModalActive && this.$route.query.isPhoneVerify == 'true') {
            this.$router
                .replace({
                query: Object.assign({}, this.$route.query, {
                    isPhoneVerify: false
                })
            })
                .catch((err) => err);
        }
    }
    reloadUser() {
        this.isPhoneVerificationModalActive = false;
        this.$emit('reloadUser');
    }
    confirmNumberDelete() {
        this.$buefy.dialog.confirm({
            title: this.$t('component.settings.profile.user_info.delete_number.title'),
            message: this.$t('component.settings.profile.user_info.delete_number.body'),
            confirmText: this.$t('component.settings.profile.user_info.delete_number.confirm'),
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => this.removeNumber()
        });
    }
    async removeNumber() {
        let deleteResult = await userRepository.deletePhoneNumber();
        if (!deleteResult) {
            VueUtilities.openErrorToast(this, this.$t('component.settings.profile.user_info.delete_number.number_delete_failed'));
        }
        else {
            VueUtilities.openSuccessToast(this, this.$t('component.settings.profile.user_info.delete_number.number_delete_success'));
            this.$emit('reloadUser');
        }
    }
    signOut() {
        this.$emit('signOut');
    }
    signOutAllDevices() {
        this.$emit('signOutAllDevices');
    }
    showChangePasswordModal() {
        this.isChangePasswordModalActive = true;
    }
    changePassword(oldPwd, newPwd) {
        this.isChangePasswordModalActive = false;
        this.$emit('changePassword', oldPwd, newPwd);
    }
    closePasswordModal(data) {
        this.isChangePasswordModalActive = false;
    }
    showDeleteAccountModal() {
        this.isDeleteAccountModalActive = true;
    }
    deleteAccount() {
        this.isDeleteAccountModalActive = false;
        this.$emit('deleteAccount');
    }
    closeDeleteAccountModal() {
        this.isDeleteAccountModalActive = false;
    }
};
__decorate([
    Prop({ type: Object })
], UserInfoComponent.prototype, "currentUser", void 0);
__decorate([
    Prop({ type: Object })
], UserInfoComponent.prototype, "dateTimeManager", void 0);
UserInfoComponent = __decorate([
    Component({
        components: {
            DeleteAccountModal,
            ResendVerificationModal,
            VerifyPhoneNumberModal,
            ChangePasswordModal
        }
    })
], UserInfoComponent);
export default UserInfoComponent;
