import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import NotificationCard from '@/views/components/notification/NotificationCard.vue';
import PauseNotificationsMessage from '@/views/components/notification/PauseNotificationsMessage.vue';
import LoggerExpirationList from '@/views/components/logger/LoggerExpirationList.vue';
import NotificationRepository from '@/services/repository/NotificationRepository';
import { Notification } from '@/entities/Notification';
import EventRepository from '@/services/repository/EventRepository';
import { Event } from '@/entities/models/Event';
import LastEventsTable from '@/views/components/events/LastEventsTable.vue';
import SourceGroupRepository from '@/services/repository/SourceGroupRepository';
import { SourceGroup } from '@/entities/models/SourceGroup';
import SourceRepository from '@/services/repository/SourceRepository';
import { Source } from '@/entities/models/Source';
import EventDomain from '@/entities/enums/eventDomains';
import Actions from '@/services/permissions/Actions';
import Subjects from '@/services/permissions/Subjects';
import UserSettingsRepository from '@/services/repository/UserSettingsRepository';
import VueUtilities from '@/services/VueUtilities';
import DateTimeManager from '@/services/DateTimeManager';
import ApiResponse from '@/entities/ApiResponse';
import { UserSettingsKeys } from '@/entities/enums/UserSettingKeys';
import { vxm } from '@/store/store.vuex';
import CookiesStorage from '@/services/CookiesStorage';
import DevicesState from '../components/device/DevicesState.vue';
import DeviceRepository from '@/services/repository/DeviceRepository';
import UserRepository from '@/services/repository/UserRepository';
import AppConfig from '@/configLoader';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import StatusCards from '../components/dashboard/StatusCards.vue';
import StatusCardsMobile from '../components/dashboard/StatusCardsMobile.vue';
import DepartmentRepository from '@/services/repository/DepartmentRepository';
import FloorPlanMeasurements from '../components/dashboard/FloorPlanMeasurements.vue';
import UserPreferences from '@/services/UserPreferences';
import FloorPlanEntities from '@/entities/enums/FloorPlanEntities';
var vxDepartmentStore = vxm.departmentStore;
var vxNotificationStore = vxm.notificationStore;
const MAX_RECENT_NOTIFICATIONS = 5;
var notificationRepository;
var eventRepository;
var sourceGroupRepository;
var sourceRepository;
var userSettingsRepository;
var deviceRepository;
var userRepository;
var departmentRepository;
let MasterDashboard = class MasterDashboard extends Vue {
    constructor() {
        super(...arguments);
        this.notificationsResult = new ApiResponse();
        this.lastDaynotificationsResult = new ApiResponse();
        this.isLoading = true;
        this.lastEvents = {};
        this.sourceGroups = [];
        this.refreshAllowed = false;
        this.favoriteGroupId = null;
        this.pauseDismissedAt = 0;
        this.pauseNotificationsUntil = 0;
        this.sources = [];
        this.currentUser = null;
        this.deviceList = new Array();
        this.invitationNoticeVisible = true;
        this.floorPlans = [];
        this.selectedFloorPlan = null;
        this.selectedFloorPlanLocationData = [];
        this.selectedFloorPlanImage = null;
        this.imageType = '';
        this.lastEventsFloorPlan = {};
        this.floorPlanVisible = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.FloorPlanVisible);
        this.isFullScreenFloorPlanActive = false;
        this.isWizardAllowed = AppConfig.getConfig().features.wizard === true &&
            (ApiUserRoles.COMPANY_OWNER == vxDepartmentStore.selectedDepartmentRoleKey ||
                ApiUserRoles.COMPANY_ADMIN == vxDepartmentStore.selectedDepartmentRoleKey);
    }
    get pauseComponentVisible() {
        return Notification.pauseComponentIsVisible(this.pauseDismissedAt, this.pauseNotificationsUntil, this.notificationsResult.getData());
    }
    get floorplanEnabled() {
        return AppConfig.getConfig().features.floor_plan;
    }
    onRouteChanges() {
        this.loadDataForPage();
    }
    get notificationsPaused() {
        let now = Math.floor(new Date().getTime() / 1000);
        return this.pauseNotificationsUntil > now;
    }
    async created() {
        this.$root.$on('reloadNotifications', async () => {
            this.notificationsResult = await this.loadNotifications();
        });
        this.dateTimeManager = await DateTimeManager.CreateManager(this);
        sourceGroupRepository = new SourceGroupRepository(this);
        sourceRepository = new SourceRepository(this);
        notificationRepository = new NotificationRepository(this);
        eventRepository = new EventRepository(this);
        userSettingsRepository = UserSettingsRepository.getInstance(this);
        deviceRepository = new DeviceRepository(this);
        userRepository = new UserRepository(this);
        departmentRepository = new DepartmentRepository(this);
        if (this.$route.query.reloadTier === 'true') {
            await this.$featuresManager.loadTier();
        }
        vxm.abortController.loadingState = true;
        vxm.abortController.controller = new AbortController();
        await this.loadDataForPage();
    }
    async loadDataForPage() {
        this.isLoading = true;
        this.refreshAllowed = false;
        let allPromises = [];
        allPromises.push(this.loadSourceGroups());
        allPromises.push(this.loadNotifications());
        allPromises.push(this.loadLastEvents());
        allPromises.push(this.loadSources());
        allPromises.push(userSettingsRepository.loadUserSettings());
        allPromises.push(deviceRepository.getDevices());
        allPromises.push(userSettingsRepository.loadUserSettingsForDepartment(this.currentUser));
        allPromises.push(userRepository.getInvitationsList());
        allPromises.push(this.loadLastDayNotifications());
        if (this.floorplanEnabled) {
            allPromises.push(departmentRepository.getFloorPlans()); // ZMENENE NA [] ked nic nie je v departmente
        }
        await Promise.all(allPromises).then((response) => {
            this.processLoadedDataForPage(response[0], response[1], response[2], response[3], response[4], response[5], response[6], response[7], response[8], response[9]);
        });
    }
    async processLoadedDataForPage(sourceGroups, lastNotificationsResult, lastEvents, sources, settings, deviceResult, departmentUserSettings, invitations, lastDayNotificationsResult, floorPlans) {
        this.currentUser = CookiesStorage.loadData(this, CookiesStorage.keys.API_USER_ID);
        this.notificationsResult = lastNotificationsResult;
        this.favoriteGroupId = departmentUserSettings.preferences.favoriteGroupId;
        this.sortPreference = settings.preferences.measurementSort;
        this.pauseDismissedAt = parseInt(departmentUserSettings.notifications.pause.msgDismissedAt);
        this.pauseNotificationsUntil = parseInt(departmentUserSettings.notifications.pause.until);
        this.deviceResult = deviceResult;
        Source.sortByName(sources.getData());
        this.sources = sources.getData();
        this.sourceGroups = SourceGroup.assignSourcesToSourceGroups(sourceGroups.getData(), sources.getData());
        this.invitationNoticeVisible = invitations.filter((x) => x.validUntil * 1000 >= Date.now()).length > 0;
        this.lastDaynotificationsResult = lastDayNotificationsResult;
        this.lastEvents = Event.calculateBoundaryDifference(lastEvents, this.sources);
        let measuredEventsForUnassignedSources = Event.findEventsBySourceGroupId(this.lastEvents, null, EventDomain.MEASURED);
        if (measuredEventsForUnassignedSources.length > 0) {
            let unassignedSources = Source.getUnassignedSources(sources.getData());
            this.sourceGroups.push(SourceGroup.createNullSourceGroup(unassignedSources));
        }
        SourceGroup.sort(this.sourceGroups, this.favoriteGroupId, this.sortPreference);
        this.deviceList = deviceResult.getData();
        if (this.floorplanEnabled) {
            this.floorPlans = floorPlans;
            if (this.floorPlans.length > 0) {
                this.selectedFloorPlan = floorPlans[0];
                await this.loadDataForFloorplan(floorPlans[0].id);
            }
        }
        this.isLoading = false;
        this.refreshAllowed = true;
    }
    async loadDataForFloorplan(id) {
        let allPromises = [];
        allPromises.push(departmentRepository.getFloorPlanImage(id));
        allPromises.push(departmentRepository.getFloorPlanData(id));
        await Promise.all(allPromises).then(async (response) => {
            this.selectedFloorPlanImage = response[0]?.data || null;
            this.selectedFloorPlanLocationData = response[1];
            await this.loadLastEventsFloorPlan();
        });
    }
    async setSelectedFloorplan(id) {
        this.selectedFloorPlan = this.floorPlans.find((x) => x.id === id);
        await this.loadDataForFloorplan(this.selectedFloorPlan.id);
        this.$refs.floorplanMeasurements.loadFloorPlan();
    }
    toggleFloorplan(value) {
        this.isFullScreenFloorPlanActive = value;
    }
    closeFullScreenFloorPlan() {
        this.isFullScreenFloorPlanActive = false;
    }
    floorPlanCollapseChanged(isOpen) {
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.FloorPlanVisible, isOpen);
    }
    get aliveEvents() {
        let keys = Object.keys(this.lastEvents);
        let filteredRecord = {};
        keys.forEach((key) => {
            let events = this.lastEvents[key];
            let filteredEvents = events.filter((x) => this.sources.find((y) => y.id === x.sourceId)?.logger?.isOnline === true &&
                x.measuredAt > Math.floor(new Date().getTime() / 1000) - 86400);
            if (!filteredEvents || filteredEvents.length <= 0)
                return;
            filteredRecord[key] = filteredEvents;
        });
        return filteredRecord;
    }
    get aliveEventsFloorPlan() {
        let keys = Object.keys(this.lastEventsFloorPlan);
        let filteredRecord = {};
        keys.forEach((key) => {
            let events = this.lastEventsFloorPlan[key];
            let filteredEvents = events.filter((x) => this.sources.find((y) => y.id === x.sourceId)?.logger?.isOnline === true &&
                x.measuredAt > Math.floor(new Date().getTime() / 1000) - 86400);
            if (!filteredEvents || filteredEvents.length <= 0)
                return;
            filteredRecord[key] = filteredEvents;
        });
        return filteredRecord;
    }
    async loadSourceGroups() {
        return sourceGroupRepository.getSourceGroups();
    }
    async loadNotifications() {
        let pagination = {
            page: 1,
            pageSize: MAX_RECENT_NOTIFICATIONS
        };
        return notificationRepository.getNotifications(pagination, undefined, undefined, undefined, this.getSelectedDepartmentId());
    }
    async loadLastDayNotifications() {
        let today = new Date();
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        let dateRange = [yesterday, today];
        let convertedDateRange = dateRange.map((x) => this.dateTimeManager.getTimeStringForInputDate(x));
        return notificationRepository.getNotifications(undefined, undefined, undefined, convertedDateRange, this.getSelectedDepartmentId());
    }
    async loadLastEvents() {
        return eventRepository.getLastEventsForSources([], null, this.getSelectedDepartmentId());
    }
    async loadLastEventsFloorPlan() {
        let allPromises = [];
        let sourceEntities = this.selectedFloorPlanLocationData.filter((x) => x.entity == FloorPlanEntities.SOURCE);
        allPromises.push(eventRepository.getLastEventsForSources(sourceEntities.map((x) => x.entityId), null, this.getSelectedDepartmentId()));
        await Promise.all(allPromises).then((response) => {
            this.lastEventsFloorPlan = response[0];
        });
    }
    async loadSources() {
        return sourceRepository.getVisibleSources(null, null, this.getSelectedDepartmentId());
    }
    async notificationRemoved(notifIds) {
        await notificationRepository.markNotificationsAsRead(notifIds);
        this.$root.$emit('reloadNotifications');
    }
    async refreshMeasurements() {
        this.refreshAllowed = false;
        this.lastEvents = {};
        this.lastEventsFloorPlan = {};
        let allPromises = [];
        allPromises.push(this.loadSourceGroups());
        allPromises.push(this.loadLastEvents());
        allPromises.push(this.loadSources());
        await Promise.all(allPromises).then((response) => {
            this.processDataAfterRefresh(response[0], response[1], response[2]);
        });
    }
    processDataAfterRefresh(sourceGroups, lastEvents, sources) {
        this.sources = sources.getData();
        this.sourceGroups = SourceGroup.assignSourcesToSourceGroups(sourceGroups.getData(), sources.getData());
        this.lastEvents = Event.calculateBoundaryDifference(lastEvents, this.sources);
        let measuredEventsForUnassignedSources = Event.findEventsBySourceGroupId(this.lastEvents, null, EventDomain.MEASURED);
        if (measuredEventsForUnassignedSources.length > 0) {
            let unassignedSources = Source.getUnassignedSources(sources.getData());
            this.sourceGroups.push(SourceGroup.createNullSourceGroup(unassignedSources));
        }
        SourceGroup.sort(this.sourceGroups, this.favoriteGroupId, this.sortPreference);
        if (this.floorplanEnabled && this.selectedFloorPlanLocationData.length !== 0)
            this.loadLastEventsFloorPlan();
        this.refreshAllowed = true;
    }
    async favoriteSelected(id) {
        this.isLoading = true;
        let idToStore = this.favoriteGroupId == id ? '' : id;
        let departmentUserSettings = await userSettingsRepository.saveUserSettingForDepartment(this.currentUser, [UserSettingsKeys.PREFERENCES, UserSettingsKeys.FAVORITE_GROUP_ID], idToStore);
        this.favoriteGroupId = departmentUserSettings.preferences.favoriteGroupId;
        let msg = idToStore != ''
            ? this.$t('component.source_group_picker.selected_as_favorite')
            : this.$t('component.source_group_picker.favorite_removed');
        VueUtilities.openSuccessToast(this, msg);
        this.isLoading = false;
    }
    async reloadNotificationPause() {
        this.isLoading = true;
        let departmentUserSettings = await userSettingsRepository.loadUserSettingsForDepartment(this.currentUser, true);
        this.pauseDismissedAt = parseInt(departmentUserSettings.notifications.pause.msgDismissedAt);
        this.pauseNotificationsUntil = parseInt(departmentUserSettings.notifications.pause.until);
        this.isLoading = false;
    }
    get noLoggerOrDevice() {
        return this.sources.length === 0 || this.deviceList.length === 0;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
    getSelectedDepartmentPermissions() {
        return vxDepartmentStore.selectedDepartmentPermissions;
    }
    getSelectedDepartment() {
        return vxDepartmentStore.selectedDepartment;
    }
};
__decorate([
    Watch('$route')
], MasterDashboard.prototype, "onRouteChanges", null);
MasterDashboard = __decorate([
    Component({
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$ability.can(Actions.READ, Subjects.MEASURED_DATA_READ)) {
                    next();
                }
                else {
                    next({ name: 'missingPermissions' });
                }
            });
        },
        components: {
            NotificationCard,
            LastEventsTable,
            PauseNotificationsMessage,
            LoggerExpirationList,
            DevicesState,
            StatusCards,
            StatusCardsMobile,
            FloorPlanMeasurements
        }
    })
], MasterDashboard);
export default MasterDashboard;
