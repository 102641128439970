import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserRepository from '@/services/repository/UserRepository';
import VueUtilities from '@/services/VueUtilities';
import ApiStatus from '@/entities/enums/apiStatuses';
import ApiResponseCodes from '@/entities/enums/apiResponseCodes';
var userRepository;
let VerifyPhoneNumberModal = class VerifyPhoneNumberModal extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.editedPhoneNumber = '';
        this.verificationSent = false;
        this.isFinished = false;
        this.verificationCode = '';
        this.invalidNumber = false;
    }
    get phoneNumberAdditionalError() {
        if (this.invalidNumber)
            return this.$t('component.verify_phone.msg.phone_number_invalid');
        else
            return null;
    }
    created() {
        userRepository = new UserRepository(this);
        this.phoneNumber = this.phoneNumberDto ? this.phoneNumberDto.Number : '';
        this.editedPhoneNumber = this.phoneNumber;
    }
    beforeDestroy() {
        if (this.isFinished == true)
            this.$emit('verificationFinished', true);
    }
    update() {
        let numberChanged = this.phoneNumber != this.editedPhoneNumber;
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                if (numberChanged) {
                    if (this.phoneNumberDto) {
                        let deleteResult = await userRepository.deletePhoneNumber();
                        if (!deleteResult) {
                            VueUtilities.openErrorToast(this, this.$t('component.verify_phone.msg.edit_failed'));
                            this.isLoading = false;
                            return;
                        }
                    }
                    let createResult = await userRepository.createPhoneNumber(this.editedPhoneNumber);
                    if (createResult != true) {
                        if (createResult.status == ApiStatus.BAD_REQUEST &&
                            createResult.code == ApiResponseCodes.INVALID_PHONE_NUMBER) {
                            this.invalidNumber = true;
                        }
                        else {
                            VueUtilities.openErrorToast(this, this.$t('component.verify_phone.msg.edit_failed'));
                        }
                        this.isLoading = false;
                        return;
                    }
                    else {
                        VueUtilities.openSuccessToast(this, this.$t('component.verify_phone.msg.edit_success'));
                        this.isLoading = false;
                    }
                    this.$emit('reloadUser');
                }
            }
        });
    }
    verify() {
        let numberChanged = this.phoneNumber != this.editedPhoneNumber;
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                if (numberChanged) {
                    if (this.phoneNumberDto) {
                        let deleteResult = await userRepository.deletePhoneNumber();
                        if (!deleteResult) {
                            VueUtilities.openErrorToast(this, this.$t('component.verify_phone.msg.edit_failed'));
                            this.isLoading = false;
                            return;
                        }
                    }
                    let createResult = await userRepository.createPhoneNumber(this.editedPhoneNumber);
                    if (createResult != true) {
                        if (createResult.status == ApiStatus.BAD_REQUEST &&
                            createResult.code == ApiResponseCodes.INVALID_PHONE_NUMBER) {
                            this.invalidNumber = true;
                        }
                        else {
                            VueUtilities.openErrorToast(this, this.$t('component.verify_phone.msg.edit_failed'));
                        }
                        this.isLoading = false;
                        return;
                    }
                }
                let verifyResult = await userRepository.sendVerificationToCodePhoneNumber(this.editedPhoneNumber);
                if (verifyResult != true) {
                    if (verifyResult.status == ApiStatus.BAD_REQUEST &&
                        verifyResult.code == ApiResponseCodes.CANNOT_SEND_VERIFICATION) {
                        VueUtilities.openErrorToast(this, this.$t('component.verify_phone.msg.cannot_send_verification'));
                    }
                    else {
                        VueUtilities.openErrorToast(this, this.$t('component.verify_phone.msg.verify_failed'));
                    }
                    this.isLoading = false;
                    return;
                }
                else {
                    VueUtilities.openSuccessToast(this, this.$t('component.verify_phone.msg.verify_sent'));
                    this.isLoading = false;
                    this.verificationSent = true;
                }
            }
        });
    }
    checkVerification() {
        this.$validator.validateAll().then(async (result) => {
            if (result) {
                this.isLoading = true;
                let checkResult = await userRepository.verifyPhoneNumber(this.editedPhoneNumber, this.verificationCode);
                if (checkResult != true) {
                    if (checkResult.status == ApiStatus.BAD_REQUEST &&
                        checkResult.code == ApiResponseCodes.CANNOT_CHECK_VERIFICATION) {
                        VueUtilities.openErrorToast(this, this.$t('component.verify_phone.msg.cannot_check_verification'));
                    }
                    else {
                        VueUtilities.openErrorToast(this, this.$t('component.verify_phone.msg.check_failed'));
                    }
                    this.isLoading = false;
                    return;
                }
                else {
                    VueUtilities.openSuccessToast(this, this.$t('component.verify_phone.msg.success'));
                    this.isLoading = false;
                    this.isFinished = true;
                    this.$emit('reloadUser');
                }
            }
        });
    }
    closeModal(event) {
        this.$emit('modalClosed', event);
    }
    numberChanged() {
        this.invalidNumber = false;
    }
};
__decorate([
    Prop({ type: Object })
], VerifyPhoneNumberModal.prototype, "phoneNumberDto", void 0);
VerifyPhoneNumberModal = __decorate([
    Component({})
], VerifyPhoneNumberModal);
export default VerifyPhoneNumberModal;
