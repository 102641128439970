export var DateRangeType;
(function (DateRangeType) {
    DateRangeType["ONE_DAY"] = "one_day";
    DateRangeType["TWO_DAYS"] = "two_days";
    DateRangeType["ONE_WEEK"] = "one_week";
    DateRangeType["TWO_WEEKS"] = "two_weeks";
    DateRangeType["LAST_MONTH"] = "last_month";
    DateRangeType["MONTH"] = "month";
    DateRangeType["RANGE"] = "range";
})(DateRangeType || (DateRangeType = {}));
