var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navbar has-shadow is-fixed-top is-grey" }, [
    _c(
      "div",
      {
        staticClass: "navbar-menu is-flex is-justify-content-space-between",
        attrs: { id: "navMenu" },
      },
      [
        _c("div"),
        _c(
          "div",
          { staticClass: "navbar-brand" },
          [
            _c(
              "router-link",
              {
                staticClass: "navbar-item",
                attrs: {
                  to: {
                    name: "home",
                    params: _vm.$route.params,
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/smartCity_logo.svg"),
                    alt: "",
                  },
                }),
              ]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "navbar-end" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeUserMenu,
                  expression: "closeUserMenu",
                },
              ],
              staticClass: "navbar-item has-dropdown",
              class: { "is-active": _vm.showUserMenu },
            },
            [
              _c(
                "a",
                {
                  staticClass: "navbar-link",
                  on: {
                    click: function ($event) {
                      _vm.showUserMenu = !_vm.showUserMenu
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-user is-size-5" })]
              ),
              _c("div", { staticClass: "navbar-dropdown is-right" }, [
                _c("div", { staticClass: "navbar-item" }, [
                  _vm._v(" " + _vm._s(_vm.$t("navbar.language")) + ": "),
                  _vm.isCurrentLanguage(_vm.Languages.SLOVAK)
                    ? _c("span", { staticClass: "has-l-m" }, [
                        _vm._v(_vm._s(_vm.$t("navbar.slovak"))),
                      ])
                    : _c(
                        "a",
                        {
                          staticClass: "has-l-m",
                          on: {
                            click: function ($event) {
                              return _vm.changeLanguage(_vm.Languages.SLOVAK)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("navbar.slovak")))]
                      ),
                  _vm.isCurrentLanguage(_vm.Languages.ENGLISH)
                    ? _c("span", { staticClass: "has-l-m" }, [
                        _vm._v(_vm._s(_vm.$t("navbar.english"))),
                      ])
                    : _c(
                        "a",
                        {
                          staticClass: "has-l-m",
                          on: {
                            click: function ($event) {
                              return _vm.changeLanguage(_vm.Languages.ENGLISH)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("navbar.english")))]
                      ),
                  _vm.isCurrentLanguage(_vm.Languages.CZECH)
                    ? _c("span", { staticClass: "has-l-m" }, [
                        _vm._v(_vm._s(_vm.$t("navbar.czech"))),
                      ])
                    : _c(
                        "a",
                        {
                          staticClass: "has-l-m",
                          on: {
                            click: function ($event) {
                              return _vm.changeLanguage(_vm.Languages.CZECH)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("navbar.czech")))]
                      ),
                ]),
              ]),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }