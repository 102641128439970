var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("div", { staticClass: "level section-header-level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("h2", { staticClass: "level-item subtitle is-4" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("admin.component.company.detail.companyUsers.title")
                ) +
                " "
            ),
          ]),
        ]),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            [
              _c(
                "b-dropdown",
                {
                  attrs: { position: "is-bottom-left", "aria-role": "list" },
                  scopedSlots: _vm._u([
                    {
                      key: "trigger",
                      fn: function () {
                        return [
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "admin.component.company.list.actions.columns"
                                ),
                                position: "is-left",
                                type: "is-dark",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "is-dark-grey",
                                  staticStyle: { "font-size": "1rem" },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { type: "is-primary", icon: "list" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(_vm.tableColumns, function (column) {
                  return _c(
                    "b-dropdown-item",
                    {
                      key: column.field,
                      staticClass: "custom-dropdown-item",
                      attrs: {
                        focusable: false,
                        custom: "",
                        "aria-role": "listitem",
                      },
                    },
                    [
                      _c(
                        "b-checkbox",
                        {
                          attrs: {
                            disabled:
                              column.field == _vm.tableColumns.fullName.field,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.chooseTableColumns(
                                column.field,
                                $event
                              )
                            },
                          },
                          model: {
                            value: column.visible,
                            callback: function ($$v) {
                              _vm.$set(column, "visible", $$v)
                            },
                            expression: "column.visible",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "admin.component.company.detail.companyUsers.list." +
                                    column.field
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "b-table",
        {
          staticClass: "table-wrap",
          attrs: {
            data: _vm.users,
            striped: "",
            "default-sort-direction": _vm.sortDirection,
            "default-sort": _vm.sortField,
          },
        },
        [
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.fullName.field,
              visible: _vm.tableColumns.fullName.visible,
              label: _vm.$t(
                "admin.component.company.detail.companyUsers.list." +
                  _vm.tableColumns.fullName.field
              ),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (users) {
                  return [
                    users.row.email !== null
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "adminUserDetail",
                                params: {
                                  userId: users.row.apiUserId,
                                  title: _vm.company.companyName,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(users.row.fullName))]
                        )
                      : _c("span", [_vm._v(_vm._s(users.row.fullName))]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.email.field,
              visible: _vm.tableColumns.email.visible,
              label: _vm.$t(
                "admin.component.company.detail.companyUsers.list." +
                  _vm.tableColumns.email.field
              ),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (users) {
                  return [
                    users.row.email !== null
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "adminUserDetail",
                                params: {
                                  userId: users.row.apiUserId,
                                  title: _vm.company.companyName,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(users.row.email))]
                        )
                      : _c("span", [_vm._v(_vm._s(users.row.email))]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.username.field,
              visible: _vm.tableColumns.username.visible,
              label: _vm.$t(
                "admin.component.company.detail.companyUsers.list." +
                  _vm.tableColumns.username.field
              ),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (users) {
                  return [
                    users.row.email !== null
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "adminUserDetail",
                                params: {
                                  userId: users.row.apiUserId,
                                  title: _vm.company.companyName,
                                  lang: _vm.$route.params.lang,
                                },
                              },
                            },
                          },
                          [_vm._v(_vm._s(users.row.username))]
                        )
                      : _c("span", [_vm._v(_vm._s(users.row.username))]),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.roleKey.field,
              visible: _vm.tableColumns.roleKey.visible,
              label: _vm.$t(
                "admin.component.company.detail.companyUsers.list." +
                  _vm.tableColumns.roleKey.field
              ),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (users) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("users.roles." + users.row.role.Key)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.enabled.field,
              visible: _vm.tableColumns.enabled.visible,
              label: _vm.$t(
                "admin.component.company.detail.companyUsers.list." +
                  _vm.tableColumns.enabled.field
              ),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (users) {
                  return [
                    _c("b-icon", {
                      attrs: { icon: users.row.enabled ? "check" : "times" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: _vm.tableColumns.assignedAt.field,
              visible: _vm.tableColumns.assignedAt.visible,
              label: _vm.$t(
                "admin.component.company.detail.companyUsers.list." +
                  _vm.tableColumns.assignedAt.field
              ),
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (users) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm.dateTimeManager.formatTime(
                          new Date(users.row.assignedAt * 1000),
                          null,
                          null,
                          null
                        )
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("b-table-column", {
            attrs: {
              field: "action",
              label: _vm.$t(
                "admin.component.company.detail.companyUsers.list.action"
              ),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (users) {
                  return [
                    _c(
                      "b-field",
                      [
                        _vm.currentUserId !== users.row.apiUserId &&
                        users.row.email !== null
                          ? _c(
                              "b-select",
                              {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "admin.component.company.detail.companyUsers.list.actionSelected.action_placeholder"
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.actionSelectedUser(
                                      users.row,
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value: users.row.selectedAction,
                                  callback: function ($$v) {
                                    _vm.$set(users.row, "selectedAction", $$v)
                                  },
                                  expression: "users.row.selectedAction",
                                },
                              },
                              [
                                _vm.$ability.can(
                                  _vm.$permActions.CHANGE_ROLE,
                                  _vm.$permSubjects.ADMIN_DEPARTMENTS_USERS
                                )
                                  ? _c(
                                      "option",
                                      {
                                        key: _vm.Action.CHANGE_ROLE,
                                        domProps: {
                                          value: _vm.Action.CHANGE_ROLE,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "admin.component.company.detail.companyUsers.list.actionSelected." +
                                                  _vm.Action.CHANGE_ROLE
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.$ability.can(
                                  _vm.$permActions.REMOVE,
                                  _vm.$permSubjects.ADMIN_DEPARTMENTS_USERS
                                )
                                  ? _c(
                                      "option",
                                      {
                                        key: _vm.Action.UNASSIGN,
                                        domProps: {
                                          value: _vm.Action.UNASSIGN,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "admin.component.company.detail.companyUsers.list.actionSelected." +
                                                  _vm.Action.UNASSIGN
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                users.row.enabled &&
                                _vm.$ability.can(
                                  _vm.$permActions.DISABLE,
                                  _vm.$permSubjects.ADMIN_DEPARTMENTS_USERS
                                )
                                  ? [
                                      _c(
                                        "option",
                                        {
                                          key: _vm.Action.DISABLE,
                                          domProps: {
                                            value: _vm.Action.DISABLE,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "admin.component.company.detail.companyUsers.list.actionSelected." +
                                                    _vm.Action.DISABLE
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm.$ability.can(
                                      _vm.$permActions.ENABLE,
                                      _vm.$permSubjects.ADMIN_DEPARTMENTS_USERS
                                    )
                                  ? [
                                      _c(
                                        "option",
                                        {
                                          key: _vm.Action.ENABLE,
                                          domProps: {
                                            value: _vm.Action.ENABLE,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "admin.component.company.detail.companyUsers.list.actionSelected." +
                                                    _vm.Action.ENABLE
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "admin.component.company.detail.companyUsers.list.empty"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }