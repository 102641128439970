import { __decorate } from "tslib";
import LoggedUserManager from '@/services/LoggedUserManager';
import VueUtilities from '@/services/VueUtilities';
import AuthenticationCalls from '@/services/AuthenticationCalls';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import AppConfig from '@/configLoader';
import SettingOptions from '@/entities/enums/SettingOptions';
import { MenuCategoryKeys } from '@/entities/interfaces/MenuCategories';
import ApiUserRoles from '@/entities/enums/ApiUserRoles';
import SessionStorage from '@/services/repository/SessionStorage';
import { vxm } from '@/store/store.vuex';
var authenticationCalls;
var vxDepartmentStore = vxm.departmentStore;
var vxNotificationStore = vxm.notificationStore;
let NavSideBar = class NavSideBar extends Vue {
    constructor() {
        super(...arguments);
        this.isSideMenuVisible = false;
        this.currentConfig = AppConfig.getConfig();
        this.SettingOptions = SettingOptions;
        this.isWizardAllowed = AppConfig.getConfig().features.wizard === true &&
            (ApiUserRoles.COMPANY_OWNER == vxDepartmentStore.selectedDepartmentRoleKey ||
                ApiUserRoles.COMPANY_ADMIN == vxDepartmentStore.selectedDepartmentRoleKey);
        this.mainCategories = {
            DASHBOARD: {
                name: MenuCategoryKeys.DASHBOARD,
                expandable: false,
                expanded: false
            },
            GROUP_DASHBOARD: {
                name: MenuCategoryKeys.GROUP_DASHBOARD,
                expandable: false,
                expanded: false
            },
            REPORTS: {
                name: MenuCategoryKeys.REPORTS,
                expandable: false,
                expanded: false
            },
            NOTIFICATIONS: {
                name: MenuCategoryKeys.NOTIFICATIONS,
                expandable: false,
                expanded: false
            },
            SETTINGS: {
                name: MenuCategoryKeys.SETTINGS,
                expandable: true,
                expanded: true
            },
            WIZARD: {
                name: MenuCategoryKeys.WIZARD,
                expandable: false,
                expanded: false
            },
            ABOUT: {
                name: MenuCategoryKeys.ABOUT,
                expandable: false,
                expanded: false
            },
            FEEDBACK: {
                name: MenuCategoryKeys.FEEDBACK,
                expandable: false,
                expanded: false
            },
            LOG_OUT: {
                name: MenuCategoryKeys.FEEDBACK,
                expandable: false,
                expanded: false
            },
            THERMAL_MAP: {
                name: MenuCategoryKeys.THERMAL_MAP,
                expandable: false,
                expanded: false
            },
            MAP: {
                name: MenuCategoryKeys.MAP,
                expandable: false,
                expanded: false
            }
        };
        this.settingsSubpages = [
            SettingOptions.DEVICES.activeOnPages,
            SettingOptions.LOGGERS.activeOnPages,
            SettingOptions.GROUPS.activeOnPages,
            SettingOptions.NOTIFICATIONS.activeOnPages,
            SettingOptions.ADMINISTRATION.activeOnPages,
            SettingOptions.DASHBOARDS.activeOnPages,
            SettingOptions.PROFILE.activeOnPages
        ];
    }
    get isAdmin() {
        return LoggedUserManager.isAdmin(this);
    }
    get isReportSettingsAllowed() {
        return AppConfig.getConfig().features.email_report_settings === true;
    }
    routeChanged() {
        if (this.isActive(this.settingsSubpages.flat())) {
            this.mainCategories.SETTINGS.expanded = true;
        }
    }
    created() {
        authenticationCalls = new AuthenticationCalls(this);
        if (this.isActive(this.settingsSubpages.flat())) {
            this.mainCategories.SETTINGS.expanded = true;
        }
        this.$eventBus.$on('isSideMenuVisible', (value) => {
            this.isSideMenuVisible = value;
        });
        this.$eventBus.$on('logoutAsync', (value) => {
            if (value) {
                this.logoutAsync();
            }
        });
    }
    onNavigation() {
        this.mobileExpand(false);
        if (this.isActive(this.settingsSubpages.flat())) {
            this.mainCategories.SETTINGS.expanded = true;
        }
        this.$eventBus.$emit('closeBurgerMenu', false);
        window.scrollTo(0, 0);
    }
    async logoutAsync() {
        let success = await authenticationCalls.logout();
        if (success) {
            VueUtilities.openSuccessToast(this, this.$t('component.logout.successful').toString());
            LoggedUserManager.deleteLoginData(this);
            LoggedUserManager.deleteAdminLoginData(this);
            SessionStorage.clearStorageForSession();
            vxDepartmentStore.clearData();
            vxNotificationStore.clearData();
            this.$router.push({ name: 'login', params: { lang: this.$route.params.lang } }).catch((err) => err);
        }
        else {
            VueUtilities.openErrorToast(this, this.$t('component.logout.failed').toString());
        }
    }
    isActive(routes) {
        if (Array.isArray(routes) && this.$route.name != null) {
            return routes.find((el) => el == this.$route.name) != undefined;
        }
    }
    expandButtonClicked(isExpanded) {
        this.$emit('SideMenuExpanded', isExpanded);
    }
    mobileExpand(isExpanded) {
        this.isSideMenuVisible = isExpanded;
    }
    expandCategory(event, category) {
        event.stopPropagation();
        event.preventDefault();
        category.expanded = !category.expanded;
    }
};
__decorate([
    Prop({ type: Boolean })
], NavSideBar.prototype, "isExpanded", void 0);
__decorate([
    Watch('$route')
], NavSideBar.prototype, "routeChanged", null);
NavSideBar = __decorate([
    Component({})
], NavSideBar);
export default NavSideBar;
