var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { animBoundaryArea: _vm.isBoundaryEdit } }, [
    !_vm.hasEditPermissions
      ? _c("div", [
          _vm.boundaries.length > 0
            ? _c(
                "table",
                { staticClass: "info-table min-padding has-bottom-margin" },
                [
                  _c("tr", [
                    _c("th"),
                    _c("th", { staticClass: "is-italic is-size-7" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.boundaries.lower_alarm")) +
                          " "
                      ),
                    ]),
                    _c("th", { staticClass: "is-italic is-size-7" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.boundaries.lower_warning")) +
                          " "
                      ),
                    ]),
                    _c("th", { staticClass: "is-italic is-size-7" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.boundaries.upper_warning")) +
                          " "
                      ),
                    ]),
                    _c("th", { staticClass: "is-italic is-size-7" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("component.boundaries.upper_alarm")) +
                          " "
                      ),
                    ]),
                    _c("th"),
                  ]),
                  _vm._l(_vm.boundaries, function (boundary) {
                    return _c("tr", { key: boundary.eventTypeSlug }, [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("services." + boundary.measuredDataTypeSlug)
                          )
                        ),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.DecimalConversion.toCommaString(
                              boundary.lowerAlarmBoundary
                            )
                          ) + _vm._s(boundary.unit)
                        ),
                      ]),
                      _c("td", [
                        boundary.lowerWarningBoundary != null
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.DecimalConversion.toCommaString(
                                      boundary.lowerWarningBoundary
                                    )
                                  ) +
                                  _vm._s(boundary.unit) +
                                  " "
                              ),
                            ])
                          : _c("span", [_vm._v("-")]),
                      ]),
                      _c("td", [
                        boundary.upperWarningBoundary != null
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.DecimalConversion.toCommaString(
                                    boundary.upperWarningBoundary
                                  )
                                ) + _vm._s(boundary.unit)
                              ),
                            ])
                          : _c("span", [_vm._v("-")]),
                      ]),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.DecimalConversion.toCommaString(
                              boundary.upperAlarmBoundary
                            )
                          ) + _vm._s(boundary.unit)
                        ),
                      ]),
                    ])
                  }),
                ],
                2
              )
            : _c("p", { staticClass: "is-italic" }, [
                _vm._v(_vm._s(_vm.$t("boundaries.boundaries_empty_label"))),
              ]),
        ])
      : _c(
          "div",
          [
            _vm.newBoundaries.length > 0
              ? _c(
                  "div",
                  { staticClass: "mt-3" },
                  _vm._l(_vm.newBoundaries, function (boundary) {
                    return _c(
                      "div",
                      { key: boundary.eventTypeSlug, staticClass: "mb-6" },
                      [
                        _c("div", {}, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "boundaries-form-header mb-3 is-flex is-flex-direction-row is-align-items-center",
                            },
                            [
                              boundary.measuredDataTypeSlug != null
                                ? _c(
                                    "p",
                                    { staticClass: "has-text-weight-bold" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "services." +
                                                boundary.measuredDataTypeSlug
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "b-field",
                                    {
                                      attrs: {
                                        type: {
                                          "is-danger": _vm.errors.has(
                                            _vm.$t(
                                              "component.boundaries.service"
                                            )
                                          ),
                                        },
                                        message: _vm.errors.first(
                                          _vm.$t("component.boundaries.service")
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "b-select",
                                        {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          attrs: {
                                            placeholder: _vm.$t(
                                              "component.boundaries.select_type"
                                            ),
                                            name: _vm.$t(
                                              "component.boundaries.service"
                                            ),
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.typeSelected(
                                                boundary,
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.notBoundedEventTypes,
                                          function (option) {
                                            return _c(
                                              "option",
                                              {
                                                key: option.eventTypeId,
                                                domProps: {
                                                  value: option.slug,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "services." +
                                                          option.name.toLowerCase()
                                                      ) +
                                                        " (" +
                                                        _vm.eventTypes.find(
                                                          function (x) {
                                                            return (
                                                              x.slug ==
                                                              option.slug
                                                            )
                                                          }
                                                        ).unit.unit +
                                                        ")"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ],
                                    1
                                  ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "ml-3",
                                  attrs: {
                                    type: "is-primary",
                                    size: "is-small",
                                    outlined: "",
                                    "icon-left": "times-circle",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeBoundary(
                                        boundary.measuredDataTypeSlug
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("generals.remove")))]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "columns boundaries-container is-multiline is-mobile",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "column is-half-mobile pb-0-mobile",
                              },
                              [
                                _c(
                                  "b-field",
                                  {
                                    staticClass: "my-2",
                                    attrs: {
                                      type: {
                                        "is-danger":
                                          _vm.errors.has(
                                            _vm.$t(
                                              "component.boundaries.lower_alarm"
                                            ) +
                                              " (" +
                                              _vm.$t(
                                                "services." +
                                                  boundary.measuredDataTypeSlug
                                              ) +
                                              ")"
                                          ) ||
                                          _vm.hasErrorMsg(
                                            boundary.measuredDataTypeSlug,
                                            _vm.BoundaryID.LA
                                          ),
                                      },
                                      message: _vm.errors.has(
                                        _vm.$t(
                                          "component.boundaries.lower_alarm"
                                        ) +
                                          " (" +
                                          _vm.$t(
                                            "services." +
                                              boundary.measuredDataTypeSlug
                                          ) +
                                          ")"
                                      )
                                        ? _vm.$t("validation.lowerBoundary")
                                        : _vm.getErrorMsg(
                                            boundary.measuredDataTypeSlug,
                                            _vm.BoundaryID.LA
                                          ),
                                      label: _vm.$t(
                                        "component.boundaries.lower_alarm"
                                      ),
                                      "custom-class": "is-size-7 mb-0",
                                    },
                                  },
                                  [
                                    _c("b-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required|decimalNumber",
                                          expression:
                                            "'required|decimalNumber'",
                                        },
                                      ],
                                      attrs: {
                                        name:
                                          _vm.$t(
                                            "component.boundaries.lower_alarm"
                                          ) +
                                          " (" +
                                          _vm.$t(
                                            "services." +
                                              boundary.measuredDataTypeSlug
                                          ) +
                                          ")",
                                        placeholder: _vm.$t(
                                          "component.boundaries.lower_alarm"
                                        ),
                                      },
                                      on: { input: _vm.boundaryValueChanged },
                                      model: {
                                        value: boundary.lowerAlarmBoundary,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            boundary,
                                            "lowerAlarmBoundary",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "boundary.lowerAlarmBoundary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "column is-half-mobile pb-0-mobile",
                              },
                              [
                                _c(
                                  "b-field",
                                  {
                                    staticClass: "my-2",
                                    attrs: {
                                      type: {
                                        "is-danger":
                                          _vm.errors.has(
                                            _vm.$t(
                                              "component.boundaries.lower_warning"
                                            ) +
                                              " (" +
                                              _vm.$t(
                                                "services." +
                                                  boundary.measuredDataTypeSlug
                                              ) +
                                              ")"
                                          ) ||
                                          _vm.hasErrorMsg(
                                            boundary.measuredDataTypeSlug,
                                            _vm.BoundaryID.LW
                                          ),
                                      },
                                      message: _vm.errors.has(
                                        _vm.$t(
                                          "component.boundaries.lower_warning"
                                        ) +
                                          " (" +
                                          _vm.$t(
                                            "services." +
                                              boundary.measuredDataTypeSlug
                                          ) +
                                          ")"
                                      )
                                        ? _vm.$t("validation.lowerBoundary")
                                        : _vm.getErrorMsg(
                                            boundary.measuredDataTypeSlug,
                                            _vm.BoundaryID.LW
                                          ),
                                      label: _vm.$t(
                                        "component.boundaries.lower_warning"
                                      ),
                                      "custom-class": "is-size-7 mb-0",
                                    },
                                  },
                                  [
                                    _c("b-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "decimalNumber",
                                          expression: "'decimalNumber'",
                                        },
                                      ],
                                      attrs: {
                                        name:
                                          _vm.$t(
                                            "component.boundaries.lower_warning"
                                          ) +
                                          " (" +
                                          _vm.$t(
                                            "services." +
                                              boundary.measuredDataTypeSlug
                                          ) +
                                          ")",
                                        placeholder: _vm.$t(
                                          "component.boundaries.unused"
                                        ),
                                      },
                                      on: { input: _vm.boundaryValueChanged },
                                      model: {
                                        value: boundary.lowerWarningBoundary,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            boundary,
                                            "lowerWarningBoundary",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "boundary.lowerWarningBoundary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "column is-half-mobile" },
                              [
                                _c(
                                  "b-field",
                                  {
                                    staticClass: "my-2",
                                    attrs: {
                                      type: {
                                        "is-danger":
                                          _vm.errors.has(
                                            _vm.$t(
                                              "component.boundaries.upper_warning"
                                            ) +
                                              " (" +
                                              _vm.$t(
                                                "services." +
                                                  boundary.measuredDataTypeSlug
                                              ) +
                                              ")"
                                          ) ||
                                          _vm.hasErrorMsg(
                                            boundary.measuredDataTypeSlug,
                                            _vm.BoundaryID.UW
                                          ),
                                      },
                                      message: _vm.errors.has(
                                        _vm.$t(
                                          "component.boundaries.upper_warning"
                                        ) +
                                          " (" +
                                          _vm.$t(
                                            "services." +
                                              boundary.measuredDataTypeSlug
                                          ) +
                                          ")"
                                      )
                                        ? _vm.$t("validation.upperBoundary")
                                        : _vm.getErrorMsg(
                                            boundary.measuredDataTypeSlug,
                                            _vm.BoundaryID.UW
                                          ),
                                      label: _vm.$t(
                                        "component.boundaries.upper_warning"
                                      ),
                                      "custom-class": "is-size-7 mb-0",
                                    },
                                  },
                                  [
                                    _c("b-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "decimalNumber",
                                          expression: "'decimalNumber'",
                                        },
                                      ],
                                      attrs: {
                                        name:
                                          _vm.$t(
                                            "component.boundaries.upper_warning"
                                          ) +
                                          " (" +
                                          _vm.$t(
                                            "services." +
                                              boundary.measuredDataTypeSlug
                                          ) +
                                          ")",
                                        placeholder: _vm.$t(
                                          "component.boundaries.unused"
                                        ),
                                      },
                                      on: { input: _vm.boundaryValueChanged },
                                      model: {
                                        value: boundary.upperWarningBoundary,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            boundary,
                                            "upperWarningBoundary",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "boundary.upperWarningBoundary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "column is-half-mobile" },
                              [
                                _c(
                                  "b-field",
                                  {
                                    staticClass: "my-2",
                                    attrs: {
                                      type: {
                                        "is-danger":
                                          _vm.errors.has(
                                            _vm.$t(
                                              "component.boundaries.upper_alarm"
                                            ) +
                                              " (" +
                                              _vm.$t(
                                                "services." +
                                                  boundary.measuredDataTypeSlug
                                              ) +
                                              ")"
                                          ) ||
                                          _vm.hasErrorMsg(
                                            boundary.measuredDataTypeSlug,
                                            _vm.BoundaryID.UA
                                          ),
                                      },
                                      message: _vm.errors.has(
                                        _vm.$t(
                                          "component.boundaries.upper_alarm"
                                        ) +
                                          " (" +
                                          _vm.$t(
                                            "services." +
                                              boundary.measuredDataTypeSlug
                                          ) +
                                          ")"
                                      )
                                        ? _vm.$t("validation.upperBoundary")
                                        : _vm.getErrorMsg(
                                            boundary.measuredDataTypeSlug,
                                            _vm.BoundaryID.UA
                                          ),
                                      label: _vm.$t(
                                        "component.boundaries.upper_alarm"
                                      ),
                                      "custom-class": "is-size-7 mb-0",
                                    },
                                  },
                                  [
                                    _c("b-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required|decimalNumber",
                                          expression:
                                            "'required|decimalNumber'",
                                        },
                                      ],
                                      attrs: {
                                        name:
                                          _vm.$t(
                                            "component.boundaries.upper_alarm"
                                          ) +
                                          " (" +
                                          _vm.$t(
                                            "services." +
                                              boundary.measuredDataTypeSlug
                                          ) +
                                          ")",
                                        placeholder: _vm.$t(
                                          "component.boundaries.upper_alarm"
                                        ),
                                      },
                                      on: { input: _vm.boundaryValueChanged },
                                      model: {
                                        value: boundary.upperAlarmBoundary,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            boundary,
                                            "upperAlarmBoundary",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "boundary.upperAlarmBoundary",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c(
              "b-button",
              {
                attrs: {
                  expanded: "",
                  disabled: !_vm.canAddMoreBoundaries,
                  type: "is-primary",
                  size: "is-small",
                  "icon-left": _vm.canAddMoreBoundaries ? "plus" : null,
                  outlined: "",
                },
                on: {
                  click: function ($event) {
                    return _vm.addNewBoundary()
                  },
                },
              },
              [
                _vm.createdNumberOfBoundaries === 0
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("boundaries.add")))])
                  : _vm.createdNumberOfBoundaries === _vm.maxNumberOfBoundaries
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("boundaries.all_added")))])
                  : _c("span", [_vm._v(_vm._s(_vm.$t("boundaries.add_more")))]),
              ]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }