import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import OrganizationItem from '@/views/components/organization/OrganizationItem.vue';
import OrganizationItemList from '@/views/components/organization/OrganizationItemList.vue';
import UserPreferences from '@/services/UserPreferences';
import LoggedUserManager from '@/services/LoggedUserManager';
import { IssueType } from '@/entities/enums/IssueType';
let OrganizationCard = class OrganizationCard extends Vue {
    constructor() {
        super(...arguments);
        this.departmentName = '';
        this.IssueType = IssueType;
        this.states = {
            OK: { id: 0, name: 'fine', class: 'is-success' },
            ACKNOWLEDGED: { id: 0, name: 'acknowledged', class: 'is-success' },
            MAINTENANCE: { id: 1, name: IssueType.MAINTENANCE, class: 'is-info' },
            OFF_SOURCE: { id: 2, name: IssueType.SOURCE_OFFLINE, class: 'is-grey' },
            OFF_DEVICE: { id: 2, name: IssueType.DEVICE_OFFLINE, class: 'is-grey' },
            LOW: { id: 3, name: IssueType.LOW_BATTERY, class: 'is-warning' },
            WARN: { id: 4, name: IssueType.ALARM, alarmLevel: 'Warning', class: 'is-warning' },
            ALARM: { id: 5, name: IssueType.ALARM, alarmLevel: 'Alarm', class: 'is-danger' }
        };
        this.cardViewActive = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.cardViewActive);
    }
    get filteredDepartments() {
        return this.departments.getData().filter((dep) => dep.name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(this.departmentName
            .trim()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')));
    }
    get isLoggedFromAdmin() {
        return LoggedUserManager.isAdmin(this);
    }
    get hasAlarm() {
        return this.getIssueCountByType(IssueType.ALARM, 'Alarm') > 0;
    }
    get hasWarning() {
        return this.getIssueCountByType(IssueType.ALARM, 'Warning') > 0;
    }
    get hasLowBattery() {
        return this.getIssueCountByType(IssueType.LOW_BATTERY) > 0;
    }
    get hasOffline() {
        return (this.getIssueCountByType(IssueType.SOURCE_OFFLINE) > 0 || this.getIssueCountByType(IssueType.DEVICE_OFFLINE) > 0);
    }
    get hasMaintenance() {
        return this.getIssueCountByType(IssueType.MAINTENANCE) > 0;
    }
    get acknowledgedStates() {
        let acknowledgedCount = 0;
        this.issuesSummary.forEach((summary) => {
            const allAcknowledged = summary.issues.every((issue) => issue.notAcknowledged === 0);
            if (allAcknowledged) {
                acknowledgedCount++;
            }
        });
        return acknowledgedCount;
    }
    get fineStates() {
        let fineDepartmentsCount = 0;
        this.departments.getData().forEach((department) => {
            let departmentSummary = this.issuesSummary.find((summary) => summary.id === department.id);
            if (!departmentSummary) {
                fineDepartmentsCount++;
            }
            else {
                const allAcknowledged = departmentSummary.issues.every((issue) => issue.notAcknowledged === 0);
                if (allAcknowledged) {
                    fineDepartmentsCount++;
                }
            }
        });
        return fineDepartmentsCount;
    }
    getIssueCountByType(type, alarmLevel = null) {
        let departmentsWithNotAcknowledgedIssues = 0;
        this.issuesSummary.forEach((summary) => {
            let highestPriorityStatus = this.states.OK;
            summary.issues.forEach((issue) => {
                let currentState = Object.values(this.states).find((state) => (Array.isArray(state.name) ? state.name.includes(issue.type) : state.name === issue.type) &&
                    (!state.alarmLevel || state.alarmLevel === issue.alarmLevel));
                if (currentState && currentState.id > highestPriorityStatus.id && issue.notAcknowledged > 0)
                    highestPriorityStatus = currentState;
            });
            let matchesPriority = (Array.isArray(highestPriorityStatus.name)
                ? highestPriorityStatus.name.includes(type)
                : highestPriorityStatus.name === type) &&
                (!alarmLevel || highestPriorityStatus.alarmLevel === alarmLevel);
            if (matchesPriority) {
                let hasNotAcknowledgedIssues = summary.issues.some((issue) => issue.type === type && (alarmLevel === null || issue.alarmLevel === alarmLevel) && issue.notAcknowledged > 0);
                if (hasNotAcknowledgedIssues)
                    departmentsWithNotAcknowledgedIssues++;
            }
        });
        return departmentsWithNotAcknowledgedIssues;
    }
    reload() {
        this.$emit('reload');
    }
    favoriteSelected(id) {
        this.$emit('favoriteSelected', id);
    }
    cardView() {
        this.cardViewActive = true;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.cardViewActive, this.cardViewActive);
    }
    tableView() {
        this.cardViewActive = false;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.cardViewActive, this.cardViewActive);
    }
};
__decorate([
    Prop({ type: Object })
], OrganizationCard.prototype, "departments", void 0);
__decorate([
    Prop({ type: Array })
], OrganizationCard.prototype, "issuesSummary", void 0);
__decorate([
    Prop({ type: String, default: null })
], OrganizationCard.prototype, "favoriteDepartmentId", void 0);
OrganizationCard = __decorate([
    Component({
        components: {
            OrganizationItem,
            OrganizationItemList
        }
    })
], OrganizationCard);
export default OrganizationCard;
