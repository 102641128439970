var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("b-loading", {
            attrs: {
              "is-full-page": true,
              active: _vm.isLoading,
              "can-cancel": false,
            },
            on: {
              "update:active": function ($event) {
                _vm.isLoading = $event
              },
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "multi-header" },
        [
          _c(
            "h1",
            { staticClass: "title is-3 is-spaced has-text-centered-touch" },
            [_vm._v(" " + _vm._s(_vm.$t("component.thermal_map.title")) + " ")]
          ),
          _vm.selectedMap
            ? _c("b-button", {
                attrs: {
                  type: "is-primary",
                  label: _vm.$t("generals.delete"),
                  "icon-pack": "fa",
                  "icon-left": "trash",
                },
                on: {
                  click: function ($event) {
                    return _vm.openDeleteDialog()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.isLoading
        ? _c("ThermalMap", {
            attrs: {
              sources: _vm.sources,
              selectedMap: _vm.selectedMap,
              selectedMapImage: _vm.selectedMapImage,
              thermoMaps: _vm.thermoMaps,
              dateTimeManager: _vm.dateTimeManager,
              statistics: _vm.statistics,
            },
            on: {
              createThermoMap: _vm.createThermoMap,
              updateThermoMap: _vm.updateThermoMap,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }