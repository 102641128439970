"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Services = void 0;
var Services;
(function (Services) {
    Services[Services["TEMPERATURE"] = 0] = "TEMPERATURE";
    Services[Services["HUMIDITY"] = 1] = "HUMIDITY";
    Services[Services["UPTIME"] = 5] = "UPTIME";
    Services[Services["ANNOTATION"] = 101] = "ANNOTATION";
})(Services = exports.Services || (exports.Services = {}));
