import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import ImageUploader from 'vue-image-upload-resize/src/components/ImageUploader.vue';
import { MAX_IMG_DIMENSIONS, MAX_THUMBNAIL_SIZE, DEFAULT_MIME_TYPE } from '@/entities/models/FloorPlan';
import UserPreferences from '@/services/UserPreferences';
import FloorPlanEntities from '@/entities/enums/FloorPlanEntities';
import VueUtilities from '@/services/VueUtilities';
import { arrayBufferToBase64, dataUrlToFile, resizeImage } from '@/services/FileManager';
import UnsavedChangesDialog from '../common/UnsavedChangesDialog.vue';
let FloorPlanUpload = class FloorPlanUpload extends Vue {
    constructor() {
        super(...arguments);
        this.FloorPlanEntities = FloorPlanEntities;
        this.MAX_IMG_DIMENSIONS = MAX_IMG_DIMENSIONS;
        this.MAX_THUMBNAIL_SIZE = MAX_THUMBNAIL_SIZE;
        this.image = new Image();
        this.imageFile = null;
        this.thumbnailFile = null;
        this.ctx = null;
        this.numberOfLoggers = 0;
        this.editedLocationData = [];
        this.isLayoutList = UserPreferences.getPreference(UserPreferences.LocalStorageKeys.FloorPlanUploadLayoutList);
        this.dragok = false;
        this.startX = null;
        this.startY = null;
        this.isDragging = false;
        this.currCanvasHeight = 0;
        this.selectedFloorplanName = '';
        this.isUnsavedChangesModalActive = false;
        this.routeToNavigate = null;
    }
    get hasChanges() {
        return this.isUploading
            ? !(this.imageFile == null && this.selectedFloorplanName === '' && this.editedLocationData.length === 0)
            : this.selectedFloorplanName !== this.selectedFloorPlan.name || this.hasLocationDataChanged;
    }
    get hasLocationDataChanged() {
        if (this.editedLocationData.length !== this.selectedFloorPlanLocationData.length)
            return true;
        for (let x of this.selectedFloorPlanLocationData) {
            let data = this.editedLocationData.find((log) => log.entityId === x.entityId);
            if (!data)
                return true;
            if (this.isOldFormat()) {
                if (!((x.coordinates[0] / this.image.width) * this.canvas.width == data.coordinates[0] &&
                    (x.coordinates[1] / this.image.height) * this.canvas.height == data.coordinates[1]))
                    return true;
            }
            else {
                if (!(x.coordinates[0] * this.canvas.width === data.coordinates[0] &&
                    x.coordinates[1] * this.canvas.height === data.coordinates[1]))
                    return true;
            }
        }
        return false;
    }
    get isMobileView() {
        return window.innerWidth <= 768;
    }
    get canvasHeight() {
        return this.currCanvasHeight + 20; //cca 1.25rem
    }
    async mounted() {
        this.loadData();
    }
    async loadData() {
        if (this.selectedFloorPlanLocationData && this.selectedFloorPlanImage) {
            await new Promise((resolve, reject) => {
                this.image.src =
                    'data:' +
                        this.selectedFloorPlan.fileDetails.mimeType +
                        ';base64, ' +
                        arrayBufferToBase64(this.selectedFloorPlanImage);
                this.image.onload = resolve;
                this.image.onerror = reject;
            });
            this.image.setAttribute('crossOrigin', 'anonymous');
            this.canvas = document.getElementById('canvas');
            this.ctx = this.canvas.getContext('2d');
            // Getting and apply percentage for creating responsive image
            let percentage = this.getPercentage(this.canvas.offsetWidth, this.image.width);
            let percentageForWidth = (this.image.width / 100) * percentage;
            let percentageForHeight = (this.image.height / 100) * percentage;
            this.canvas.width = this.image.width + percentageForWidth;
            this.canvas.height = this.image.height + percentageForHeight;
            this.currCanvasHeight = this.canvas.height;
            this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
            this.selectedFloorplanName = this.selectedFloorPlan.name;
            this.editedLocationData = JSON.parse(JSON.stringify(this.selectedFloorPlanLocationData));
            this.editedLocationData.forEach((x) => (x.isDragging = false));
            this.numberOfLoggers = 1;
            if (this.isOldFormat()) {
                this.editedLocationData.forEach((x) => {
                    x.coordinates[0] = (x.coordinates[0] / this.image.width) * this.canvas.width;
                    x.coordinates[1] = (x.coordinates[1] / this.image.height) * this.canvas.height;
                    this.drawData(x.coordinates[0], x.coordinates[1]);
                });
            }
            else {
                this.editedLocationData.forEach((x) => {
                    x.coordinates[0] = x.coordinates[0] * this.canvas.width;
                    x.coordinates[1] = x.coordinates[1] * this.canvas.height;
                    this.drawData(x.coordinates[0], x.coordinates[1]);
                });
            }
        }
    }
    /**
     * Check if coordinates are in old format
     */
    isOldFormat() {
        return this.selectedFloorPlanLocationData.filter((x) => x.coordinates[0] > 1).length > 0;
    }
    async setImage(file) {
        await new Promise((resolve, reject) => {
            this.image.src = file;
            this.image.onload = resolve;
            this.image.onerror = reject;
        });
        this.image.setAttribute('crossOrigin', 'anonymous');
        this.canvas = document.getElementById('canvas');
        this.ctx = this.canvas.getContext('2d');
        // Getting and apply percentage for creating responsive image
        let percentage = this.getPercentage(this.canvas.offsetWidth, this.image.width);
        let percentageForWidth = (this.image.width / 100) * percentage;
        let percentageForHeight = (this.image.height / 100) * percentage;
        this.canvas.width = this.image.width + percentageForWidth;
        this.canvas.height = this.image.height + percentageForHeight;
        this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
        let files = document.getElementById('fileInput');
        resizeImage(this.image, files.files[0].size, MAX_THUMBNAIL_SIZE, DEFAULT_MIME_TYPE).then((thumb) => (this.thumbnailFile = dataUrlToFile(thumb, 'thumb_' + files.files[0].name, DEFAULT_MIME_TYPE)));
        this.imageFile = dataUrlToFile(this.canvas.toDataURL(DEFAULT_MIME_TYPE, 1), files.files[0].name, DEFAULT_MIME_TYPE);
        this.numberOfLoggers = 1;
    }
    getPercentage(a, b) {
        return ((a - b) / b) * 100;
    }
    getAvailableSources() {
        return this.sources.filter((source) => !this.editedLocationData.some((plan) => source.id === plan.entityId));
    }
    getAvailableDevices() {
        return this.devices.filter((device) => !this.editedLocationData.some((plan) => device.deviceId === plan.entityId));
    }
    hasDuplicate() {
        return (this.editedLocationData.map((v) => v.entityId).length >
            new Set(this.editedLocationData.map((v) => v.entityId)).size);
    }
    selectEntity(entityId, index) {
        if (this.sources.find((x) => x.id == entityId)) {
            this.editedLocationData[index].entity = FloorPlanEntities.SOURCE;
        }
        else {
            this.editedLocationData[index].entity = FloorPlanEntities.GATEWAY;
        }
    }
    myMove(e) {
        // if we're dragging anything...
        if (this.dragok) {
            // tell the browser we're handling this mouse event
            e.preventDefault();
            e.stopPropagation();
            // get the current mouse position
            let pos = this.getCursorPosition(this.canvas, e);
            // calculate the distance the mouse has moved
            // since the last mousemove
            const dx = pos.x - this.startX;
            const dy = pos.y - this.startY;
            // move each rect that isDragging
            // by the distance the mouse has moved
            // since the last mousemove
            this.editedLocationData.forEach((floorPlan) => {
                if (floorPlan.isDragging) {
                    floorPlan.coordinates[0] += dx;
                    floorPlan.coordinates[1] += dy;
                }
            });
            this.clear();
            this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
            // redraw the scene with the new rect positions
            this.editedLocationData.forEach((x, i) => {
                this.reDrawData(x.coordinates[0], x.coordinates[1], i + 1);
            });
            // reset the starting mouse position for the next mousemove
            this.startX = pos.x;
            this.startY = pos.y;
        }
    }
    // clear the canvas
    clear() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
    resetData() {
        this.resetFloorPlan();
        this.imageFile = null;
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.canvas.height = 0;
    }
    myDown(e) {
        // tell the browser we're handling this mouse event
        e.preventDefault();
        e.stopPropagation();
        // get the current mouse position
        let pos = this.getCursorPosition(this.canvas, e);
        // test each shape to see if mouse is inside
        this.dragok = false;
        this.editedLocationData.forEach((floorPlan, i) => {
            let width = this.ctx.measureText((i + 1).toString()).width;
            // test if the mouse is inside this rect
            if (!this.dragok &&
                pos.x > floorPlan.coordinates[0] - width / 2 - 7 &&
                pos.x < floorPlan.coordinates[0] + width + 10 &&
                pos.y > floorPlan.coordinates[1] - 17 &&
                pos.y < floorPlan.coordinates[1] + parseInt(this.ctx.font, 10)) {
                // if yes, set that rects isDragging=true
                this.dragok = true;
                this.isDragging = true;
                floorPlan.isDragging = true;
            }
        });
        // save the current mouse position
        this.startX = pos.x;
        this.startY = pos.y;
    }
    myUp(e) {
        // tell the browser we're handling this mouse event
        e.preventDefault();
        e.stopPropagation();
        // clear all the dragging flags
        this.dragok = false;
        this.isDragging = this.editedLocationData.find((x) => x.isDragging == true) ? true : false;
        this.editedLocationData.forEach((floorPlan) => {
            floorPlan.isDragging = false;
        });
    }
    draw(e) {
        if (!this.isDragging) {
            let pos = this.getCursorPosition(this.canvas, e);
            let clickX = pos.x;
            let clickY = pos.y;
            this.editedLocationData.push({
                entity: FloorPlanEntities.SOURCE,
                entityId: '',
                sourceId: '',
                coordinates: [pos.x, pos.y],
                isDragging: false,
                floorPlanId: ''
            });
            this.ctx.font = '15px Arial';
            let width = this.ctx.measureText(this.numberOfLoggers.toString()).width;
            this.ctx.fillStyle = '#be2528';
            this.ctx.beginPath();
            this.ctx.roundRect(clickX - width / 2 - 7, clickY - 17, width + 15, parseInt(this.ctx.font, 10) + 10, 4);
            this.ctx.fill();
            this.ctx.fillStyle = 'white';
            this.ctx.textAlign = 'center';
            this.ctx.fillText(this.numberOfLoggers.toString(), clickX, clickY);
            this.numberOfLoggers += 1;
            // scroll to new select (good in mobile view?)
            let list = this.$refs.loggerList;
            if (list) {
                list.lastElementChild.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start'
                });
            }
        }
    }
    uploadFloorPlan() {
        let locationData = [];
        this.$validator.validateAll().then(async (result) => {
            if (result && this.editedLocationData.length > 0) {
                if (this.hasDuplicate()) {
                    VueUtilities.openErrorToast(this, this.$t('component.floorPlan.duplicate'));
                }
                else {
                    for (let i = 0; i < this.editedLocationData.length; i++) {
                        this.editedLocationData[i].coordinates[0] = this.editedLocationData[i].coordinates[0] / this.canvas.width;
                        this.editedLocationData[i].coordinates[1] = this.editedLocationData[i].coordinates[1] / this.canvas.height;
                        locationData.push({
                            Entity: this.editedLocationData[i].entity,
                            SourceId: this.editedLocationData[i].entity == FloorPlanEntities.SOURCE
                                ? this.editedLocationData[i].entityId
                                : null,
                            EntityId: this.editedLocationData[i].entityId,
                            Coordinates: this.editedLocationData[i].coordinates
                        });
                    }
                    this.$emit('uploadFloorPlan', false, this.selectedFloorplanName, this.imageFile, this.thumbnailFile, locationData);
                }
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('component.floorPlan.error'));
            }
        });
    }
    updateFloorPlan() {
        let locationData = [];
        this.$validator.validateAll().then(async (result) => {
            if (result && this.editedLocationData.length > 0) {
                if (this.hasDuplicate()) {
                    VueUtilities.openErrorToast(this, this.$t('component.floorPlan.duplicate'));
                }
                else {
                    for (let i = 0; i < this.editedLocationData.length; i++) {
                        this.editedLocationData[i].coordinates[0] = this.editedLocationData[i].coordinates[0] / this.canvas.width;
                        this.editedLocationData[i].coordinates[1] = this.editedLocationData[i].coordinates[1] / this.canvas.height;
                        locationData.push({
                            Entity: this.editedLocationData[i].entity,
                            SourceId: this.editedLocationData[i].entity == FloorPlanEntities.SOURCE
                                ? this.editedLocationData[i].entityId
                                : null,
                            EntityId: this.editedLocationData[i].entityId,
                            Coordinates: this.editedLocationData[i].coordinates
                        });
                    }
                    this.$emit('updateFloorPlan', false, this.selectedFloorPlan.id, this.selectedFloorplanName, locationData);
                }
            }
            else {
                VueUtilities.openErrorToast(this, this.$t('component.floorPlan.error'));
            }
        });
    }
    changeLayout() {
        this.isLayoutList = !this.isLayoutList;
        UserPreferences.setPreference(UserPreferences.LocalStorageKeys.FloorPlanUploadLayoutList, this.isLayoutList);
        this.loadData();
    }
    cancelEditing(discard = false) {
        if (this.isUnsavedChangesModalActive) {
            if (discard)
                return this.$emit('cancelEditingFloorplan');
            else
                this.isUnsavedChangesModalActive = false;
            return;
        }
        return this.hasChanges ? (this.isUnsavedChangesModalActive = true) : this.$emit('cancelEditingFloorplan');
    }
    resetFloorPlan() {
        this.numberOfLoggers = 1;
        this.editedLocationData = [];
        this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
    }
    removeLogger(index) {
        this.editedLocationData.splice(index, 1);
        this.numberOfLoggers -= 1;
        this.clear();
        this.ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
        this.editedLocationData.forEach((x, i) => {
            this.reDrawData(x.coordinates[0], x.coordinates[1], i + 1);
        });
    }
    reDrawData(posX, posY, i) {
        this.ctx.font = '15px Arial';
        let width = this.ctx.measureText(i.toString()).width;
        this.ctx.fillStyle = '#be2528';
        this.ctx.beginPath();
        // this.ctx.roundRect(posX, posY, 20, 20, 4);
        this.ctx.roundRect(posX - width / 2 - 7, posY - 17, width + 15, parseInt(this.ctx.font, 10) + 10, 4);
        this.ctx.fill();
        this.ctx.fillStyle = 'white';
        this.ctx.textAlign = 'center';
        this.ctx.fillText(i.toString(), posX, posY);
    }
    drawData(posX, posY) {
        this.ctx.font = '15px Arial';
        let width = this.ctx.measureText(this.numberOfLoggers.toString()).width;
        this.ctx.fillStyle = '#be2528';
        this.ctx.beginPath();
        // this.ctx.roundRect(posX, posY, 20, 20, 4);
        this.ctx.roundRect(posX - width / 2 - 7, posY - 17, width + 15, parseInt(this.ctx.font, 10) + 10, 4);
        this.ctx.fill();
        this.ctx.fillStyle = 'white';
        this.ctx.textAlign = 'center';
        this.ctx.fillText(this.numberOfLoggers.toString(), posX, posY);
        this.numberOfLoggers += 1;
    }
    getCursorPosition(canvas, e) {
        // Gets click position
        let rect = canvas.getBoundingClientRect();
        return {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top
        };
    }
    getLoggerName(entityId) {
        return this.sources.find((x) => x.id == entityId).name;
    }
    getDeviceName(entityId) {
        return this.devices.find((x) => x.deviceId == entityId).deviceName;
    }
    closeUnsavedChangesModal() {
        this.isUnsavedChangesModalActive = false;
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], FloorPlanUpload.prototype, "sources", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], FloorPlanUpload.prototype, "devices", void 0);
__decorate([
    Prop({ type: Object })
], FloorPlanUpload.prototype, "selectedFloorPlan", void 0);
__decorate([
    Prop({ default: null })
], FloorPlanUpload.prototype, "selectedFloorPlanImage", void 0);
__decorate([
    Prop({ type: Array, default: null })
], FloorPlanUpload.prototype, "selectedFloorPlanLocationData", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], FloorPlanUpload.prototype, "isUploading", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], FloorPlanUpload.prototype, "isUpdating", void 0);
FloorPlanUpload = __decorate([
    Component({
        components: { ImageUploader, UnsavedChangesDialog }
    })
], FloorPlanUpload);
export default FloorPlanUpload;
