import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { latLng } from 'leaflet';
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from 'vue2-leaflet';
import SmartCitySensorRepository from '@/services/repository/SmartCitySensorRepository';
import EventType from '@/entities/EventType';
import bb, { line } from 'billboard.js';
var smartCitySensorRepository;
let MapDashboard = class MapDashboard extends Vue {
    constructor() {
        super(...arguments);
        this.EventType = EventType;
        this.isLoading = false;
        this.latLng = latLng;
        this.chartHeight = 100;
        this.chartWidth = 400;
        this.mapObject = null;
        this.bounds = null;
        this.zoom = 13;
        this.center = latLng(48.61451, 19.14149);
        this.url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        this.attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
        this.currentZoom = 11.5;
        this.currentCenter = latLng(48.61451, 19.14149);
        this.mapOptions = {
            zoomSnap: 0.5
        };
        this.sensors = [];
    }
    async created() {
        smartCitySensorRepository = new SmartCitySensorRepository(this);
        this.$nextTick(() => {
            this.loadData();
        });
    }
    async loadData() {
        this.isLoading = true;
        this.mapObject = this.$refs.smartCityMap.mapObject;
        this.bounds = this.mapObject.getBounds();
        this.sensors = await smartCitySensorRepository.getSmartCitySensors(this.bounds._southWest.lat, this.bounds._northEast.lat, this.bounds._southWest.lng, this.bounds._northEast.lng);
        this.isLoading = false;
    }
    async zoomUpdate(zoom) {
        this.bounds = this.mapObject.getBounds();
        this.currentZoom = zoom;
        this.isLoading = true;
        let newSensors = await smartCitySensorRepository.getSmartCitySensors(this.bounds._southWest.lat, this.bounds._northEast.lat, this.bounds._southWest.lng, this.bounds._northEast.lng);
        newSensors.forEach((sensor) => {
            if (this.sensors.find((x) => x.id === sensor.id) == null) {
                this.sensors.push(sensor);
            }
        });
        this.isLoading = false;
    }
    onMarkerClicked(sensor) {
        sensor.additionalProperties.popupVisibleOnMap = !sensor.additionalProperties.popupVisibleOnMap;
        if (sensor.additionalProperties.popupVisibleOnMap == true && Object.keys(sensor.data).length <= 0) {
            this.loadSensorData(sensor);
        }
    }
    createChartSelector(sensor) {
        return 'chart_' + sensor.id;
    }
    async loadSensorData(sensor) {
        let data = await smartCitySensorRepository.getSmartCitySensorData(sensor.id);
        sensor.data = data[sensor.id];
        let services = Object.keys(data[sensor.id]);
        let times = [];
        services.forEach((service) => {
            times = times.concat(data[sensor.id][service].data.map((x) => x[0]));
        });
        times = [...new Set(times)];
        let chartColumns = [];
        //prepare axis data
        chartColumns.push(['x'].concat(times.map((x) => new Date(x * 1000).toISOString())));
        services.forEach((service) => {
            chartColumns.push([service].concat(data[sensor.id][service].data.map((x) => x[1])));
        });
        this.$nextTick(() => {
            let ctx = this;
            let chart = bb.generate({
                size: {
                    height: this.chartHeight,
                    width: this.chartWidth
                },
                data: {
                    x: 'x',
                    xFormat: '%Y-%m-%dT%H:%M:%S.%MSZ',
                    columns: chartColumns,
                    type: line()
                },
                axis: {
                    y: {
                        tick: {
                            count: 3,
                            format: function (x) {
                                return x.toFixed(2);
                            }
                        }
                    },
                    x: {
                        tick: {
                            fit: false,
                            count: 5
                        },
                        type: 'timeseries'
                    }
                },
                point: {
                    show: false
                },
                legend: {
                    format: function (id) {
                        return ctx.$t('services.' + id.toLowerCase());
                    }
                },
                tooltip: {
                    format: {
                        title: function (d) {
                            return new Date(d).toLocaleString();
                        },
                        name: function (name, ratio, id) {
                            return ctx.$t('services.' + name.toLowerCase());
                        },
                        value: function (value, ratio, id) {
                            return value.toFixed(2) + sensor.data[id].unit.unit;
                        }
                    }
                },
                padding: {
                    left: 40
                },
                bindto: '#' + this.createChartSelector(sensor)
            });
        });
    }
};
MapDashboard = __decorate([
    Component({
        beforeRouteUpdate(to, from, next) {
            this.loadData();
            next(); // to resolve hook
        },
        components: { LMap, LTileLayer, LMarker, LPopup, LTooltip }
    })
], MapDashboard);
export default MapDashboard;
