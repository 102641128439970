export class ThermoMap {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => ThermoMap.define(et));
        }
        else if (data != null) {
            return ThermoMap.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let map = new ThermoMap();
        map.id = data.Id;
        map.name = data.Name;
        map.fileDetails = data.FileDetails;
        map.from = data.From;
        map.to = data.To;
        map.minValue = data.MinValue;
        map.maxValue = data.MaxValue;
        map.locations = data.Locations;
        return map;
    }
}
