export const MAX_IMG_DIMENSIONS = 4098;
export const MAX_THUMBNAIL_SIZE = 50 * 1024; // KB
export const DEFAULT_MIME_TYPE = '';
export class FloorPlan {
    constructor() { }
    static fromApi(data) {
        if (Array.isArray(data)) {
            return data.map((et) => FloorPlan.define(et));
        }
        else if (data != null) {
            return FloorPlan.define(data);
        }
        else
            return null;
    }
    static define(data) {
        let floorPlan = new FloorPlan();
        floorPlan.id = data.Id;
        floorPlan.name = data.Name;
        floorPlan.fileDetails = {
            fileName: data.FileDetails.FileName,
            fileExtension: data.FileDetails.FileExtension,
            mimeType: data.FileDetails.MimeType
        };
        return floorPlan;
    }
}
