import { ThermoMap } from '@/entities/models/ThermoMap';
import AxiosService from '@/services/api/AxiosService';
import Subjects from '../permissions/Subjects';
import Actions from '../permissions/Actions';
import ApiLinks from '@/entities/ApiLinks';
import ApiStatus from '@/entities/enums/apiStatuses';
import { vxm } from '@/store/store.vuex';
var vxDepartmentStore = vxm.departmentStore;
export default class ThermoMapRepository {
    constructor(ctx) {
        this.ctx = ctx;
    }
    async createThermoMap(map, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.EDIT, Subjects.ADMIN_THERMOMAP)) {
            throw 'Forbidden action createThermoMap';
        }
        let data = new FormData();
        data.append('Map', map.Map);
        data.append('Name', map.Name);
        data.append('Data', map.Data);
        data.append('From', map.From);
        data.append('To', map.To);
        data.append('MinValue', map.MinValue.toString());
        data.append('MaxValue', map.MaxValue.toString());
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Department.Keys.Thermomap}`;
        let response = await AxiosService.postDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.CREATED)
            return new ThermoMap();
        else
            return ThermoMap.fromApi(response.data.data);
    }
    async updateThermoMap(map, thermoMapId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.EDIT, Subjects.ADMIN_THERMOMAP)) {
            throw 'Forbidden action updateThermoMap';
        }
        let data = new FormData();
        data.append('Name', map.Name);
        data.append('Data', map.Data);
        data.append('From', map.From);
        data.append('To', map.To);
        data.append('MinValue', map.MinValue.toString());
        data.append('MaxValue', map.MaxValue.toString());
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Department.Keys.Thermomap}/${thermoMapId}`;
        let response = await AxiosService.putDataToServer(url, data, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return new ThermoMap();
        else
            return ThermoMap.fromApi(response.data.data);
    }
    async getAllThermoMaps(departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_THERMOMAP)) {
            return [];
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Department.Keys.Thermomap}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return [];
        else
            return ThermoMap.fromApi(response.data.data);
    }
    async getThermoMapImage(thermoMapId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.READ, Subjects.ADMIN_THERMOMAP)) {
            throw 'Forbidden action getThermoMapImage';
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Department.Keys.Thermomap}/${thermoMapId}${ApiLinks.Department.Keys.Image}`;
        let response = await AxiosService.getDataFromServer(url, this.ctx);
        if (response.status != ApiStatus.SUCCESS)
            return null;
        else
            return response.data;
    }
    async deleteThermoMap(thermoMapId, departmentId = this.getSelectedDepartmentId()) {
        if (!this.ctx.$ability.can(Actions.EDIT, Subjects.ADMIN_THERMOMAP)) {
            throw 'Forbidden action deleteThermoMap';
        }
        let url = `${ApiLinks.Department.AdminHome}/${departmentId}${ApiLinks.Department.Keys.Thermomap}/${thermoMapId}`;
        let response = await AxiosService.deleteDataFromServer(url, null, this.ctx);
        if (response.status == ApiStatus.NO_CONTENT)
            return true;
        else
            return false;
    }
    getSelectedDepartmentId() {
        return vxDepartmentStore.selectedDepartment.id;
    }
}
